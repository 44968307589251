import React, { useState, useEffect, useRef } from 'react';
import { IoIosClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import { useParams } from 'react-router-dom';
import { useGetSessionsByUserQuery } from '../../redux/api/sessionApi';
import SessionBox from '../sessions/session_box';
import { profileService } from '../../services/profile_services';
import { FaPrint, FaDownload, FaShareAlt, FaWhatsapp } from 'react-icons/fa';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import html2pdf from 'html2pdf.js';


const PrintInvoiceModal = ({ onClose, trainee }) => {
  const { id } = useParams();
  const [sessions, setSessions] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const { data: sessionsResponse, isLoading: isSessionsLoading } = useGetSessionsByUserQuery({
    traineeId: id,
    page: 1,
    limit: 1000,
  });
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const [profile, setProfile] = useState({ firstname: '', lastname: '' });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profileData] = await Promise.all([
          profileService.getProfile()
        ]);

        // Extract and set the sports data from profileData
        const profileSports = profileData.sports || [];
        setProfile({
          firstname: profileData.firstname,
          lastname: profileData.lastname,
        });

        // Update the sports state if needed, or use profileSports directly
        
      } catch (err) {
        setError('Failed to fetch user details');
        console.error('Error fetching user details: ', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const [errMsg, setErrMsg] = useState('');
  const invoiceRef = useRef(null);

  useEffect(() => {
    setSessions(sessionsResponse?.sessions || []);
  }, [sessionsResponse]);

  const handleSessionSelect = (session) => {
    setSelectedSessions((prevSelected) => {
      if (prevSelected.includes(session)) {
        return prevSelected.filter((s) => s !== session);
      } else {
        return [...prevSelected, session];
      }
    });
  };

  
let downloadCount = 0;

const Download = () => {
  const printContents = invoiceRef.current.innerHTML;

  // Combine invoice content and styles
  const invoiceHTML = `
    <html>
      <body>
        <div class="invoice">
          ${printContents}
        </div>
      </body>
    </html>
  `;

  // Create a unique timestamp for each download
  const timestamp = new Date().toISOString().replace(/[:.]/g, '-');

  // Define options for PDF with unique filename
  const opt = {
    margin: 1,
    filename: `invoice_${timestamp}.pdf`, // Filename includes timestamp for uniqueness
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };

  // Use html2pdf to convert the invoice HTML to PDF
  html2pdf().from(invoiceHTML).set(opt).save();
};


const handleShareOrDownloadPDF = () => {
  const printContents = invoiceRef.current.innerHTML;

  // Combine invoice content and styles for sharing
  const invoiceHTML = `
    <html>
      <body>
        <div class="invoice">
          ${printContents}
        </div>
      </body>
    </html>
  `;

  // Create a temporary element for the invoice content
  const invoiceElement = document.createElement('div');
  invoiceElement.innerHTML = invoiceHTML;

  // Generate PDF from invoice HTML
  generatePDF(invoiceElement)
    .then((pdfFile) => {
      console.log('Generated PDF:', pdfFile);

      // Attempt to share the file using the Web Share API
      if (navigator.canShare && navigator.canShare({ files: [pdfFile] })) {
        navigator
          .share({
            title: 'Invoice',
            text: 'Here is your invoice as a PDF:',
            files: [pdfFile],
          })
          .then(() => console.log('Invoice shared successfully'))
          .catch((error) => console.error('Error sharing invoice:', error));
      } else {
        // Fallback to download if Web Share API is not supported
        downloadPDF(pdfFile);
      }
    })
    .catch((error) => {
      console.error('Error generating PDF:', error);
    });
};

const generatePDF = (element) => {
  downloadCount += 1; // Increment the counter on each download

  const options = {
    margin: 1,
    filename: `invoice${downloadCount}.pdf`, // Generates 'invoice1.pdf', 'invoice2.pdf', etc.
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };

  html2pdf().from(element).set(options).save();

  return new Promise((resolve, reject) => {
    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .output('blob')
      .then((pdfBlob) => {
        const pdfFile = new File([pdfBlob], 'invoice.pdf', { type: 'application/pdf' });
        resolve(pdfFile); // Return the PDF file
      })
      .catch((error) => reject(error));
  });
};

const downloadPDF = (pdfFile) => {
  downloadCount += 1; // Increment the counter on each download

  const pdfBlobUrl = URL.createObjectURL(pdfFile);
  const link = document.createElement('a');
  link.href = pdfBlobUrl;
  link.download = `invoice${downloadCount}.pdf`; // Generates 'invoice1.pdf', 'invoice2.pdf', etc.
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  alert('PDF downloaded. You can now share it manually.');
};

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options);
  };
  const totalPaid = selectedSessions
  .filter(session => session.session_status_id == 8)  // Paid sessions
  .reduce((sum, session) => sum + parseFloat(session.price || 0), 0);

const totalPending = selectedSessions
  .filter(session => session.session_status_id != 8)  // Pending sessions
  .reduce((sum, session) => sum + parseFloat(session.price || 0), 0);

const total = totalPaid + totalPending;
  const renderSessionDetails = () => {

    return (
      <div className="overflow-x-auto">
  <table className="min-w-full table-auto border-collapse">
    <thead>
      <tr className=" border-b">
        <th className="text-left px-4 py-3 font-medium">Trainees</th>
        <th className="text-left px-4 py-3 font-medium">Date</th>
        <th className="text-left px-4 py-3 font-medium">Sport</th>
        <th className="text-left px-4 py-3 font-medium">Description</th>
        <th className="text-left px-4 py-3 font-medium">Price</th>
        <th className="text-left px-4 py-3 font-medium">Status</th>
      </tr>
    </thead>
    <tbody>
      {selectedSessions
        .slice()
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map((session, index) => {
          const subtraineeNames = session.subtrainees.length > 0 
            ? session.subtrainees.map(subtrainee => subtrainee.name).join(', ') 
            : '';
          const sportName = session.sport_name || 'Unknown Sport';
          const date = formatDate(session.date);
          const price = parseFloat(session.price) || 0;
          const note = session.note || '';
          const isPaid = session.session_status_id == 8;
          const statusText = isPaid ? 'Paid' : 'Pending';

          return (
            <tr key={index} className="border-b hover:bg-gray-50">
              <td className="px-4 py-3">{subtraineeNames}</td>
              <td className="px-4 py-3">{date}</td>
              <td className="px-4 py-3">{sportName}</td>
              <td className="px-4 py-3">{note}</td>
              <td className="px-4 py-3">{`$${price.toFixed(2)}`}</td>
              <td className="px-4 py-3" style={{ color: isPaid ? 'green' : '#ff4d01' }}>
                {statusText}
              </td>
            </tr>
          );
        })}
    </tbody>
  </table>
</div>

    );
  };

  return (
    <div
      className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="relative mx-4 px-8 py-6 w-[1000px] max-w-full h-auto max-h-[95vh] overflow-y-auto bg-white rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6 pb-2 border-b border-gray-300">
          <h2 className="font-bold text-2xl text-gray-900">Print Invoice</h2>
          <IoIosClose
            size={35}
            className="text-gray-500 cursor-pointer hover:text-red-500 transition-colors duration-200"
            onClick={onClose}
          />
        </div>

        <p style={{fontSize:'18px',marginBottom:'15px',fontWeight:'bold'}}>Please Select Sessions</p>
          <p className="font-semibold text-lg mb-3">
            Sessions ({sessionsResponse?.total_sessions})
          </p>
       
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 overflow-y-auto max-h-[300px] mb-4">
          {sessions.map((session, index) => (
            
              <div key={index} className="flex items-center text-xs">
                <input
                  type="checkbox"
                  id={`session-${session.id}`}
                  className="mr-2"
                  checked={selectedSessions.includes(session)}
                  onChange={() => handleSessionSelect(session)}
                />
                <label htmlFor={`session-${session.id}`}>
                  <SessionBox session={session} className="w-32 h-12" /> {/* Modify the size here */}
                </label>
              </div>
            
          ))}
        </div>
     
        <div
  className="invoice mt-6"
  ref={invoiceRef}
  style={{
    maxWidth: '900px',
    margin: 'auto',
    border: 'none',
    padding: '30px',
    borderRadius: '15px',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
    background: '#ffffff',
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '15px',
  }}
>
  <div className="invoice-header" style={{ marginBottom: '20px' }}>
    <h3 className="invoice-heading text-2xl md:text-3xl font-semibold text-blue-600">Invoice</h3>
    <hr className="invoice-divider border-none border-t-2 border-gray-300 my-3" />

    <div className="invoice-body flex flex-col md:flex-row justify-between">
      <div className="left-section w-full md:w-1/2 mb-4 md:mb-0">
        <p className="invoice-info mb-4 text-sm md:text-lg">
          <b>Invoice Date:</b> {new Date().toLocaleDateString('en-GB')}
        </p>
        <p className="invoice-info mb-4 text-sm md:text-lg">
          <b>Invoice To:</b> {trainee.firstname} {trainee.lastname}
        </p>
      </div>

      <div className="right-section w-full md:w-1/2">
        <p className="invoice-info mb-4 text-sm md:text-lg">
          <b>Trainer:</b> {profile.firstname} {profile.lastname}
        </p>
        <p className="invoice-info mb-4 text-sm md:text-lg">
          <b>Sessions:</b> {selectedSessions.length}
        </p>
      </div>
    </div>

    {renderSessionDetails()}

    <div className="text-right text-sm md:text-lg mt-4">
      <p><span className="font-bold">Total of Paid Sessions: </span> <span className="text-green-600">${totalPaid.toFixed(2)}</span></p>
      <p><span className="font-bold">Total of Pending Sessions: </span> <span className="text-red-600">${totalPending.toFixed(2)}</span></p>
      <p><span className="font-bold">Total: </span> <span>${total.toFixed(2)}</span></p>
    </div>

    <div className="mt-6 text-base font-roboto">
      <p>
        <span className="font-bold">POWERED BY:</span>
        <br />
        <span className="font-bold mt-2 block text-lg">
          CLICK COACH
        </span>
      </p>
    </div>
  </div>
</div>





        {/* Print Button */}
       

        <div className="flex justify-end items-center">
  <button
    className="text-red-500 hover:text-red-700 ml-3"
    onClick={() => {
      if (selectedSessions.length === 0) {
        setErrMsg('Please select at least one session to print the invoice.');
      } else {
        setErrMsg('');
  
        // Make the invoice visible
        invoiceRef.current.classList.remove('hidden');
  
        // Call handleShare and close the modal after a slight delay
        setTimeout(() => {
          Download();
          onClose();
  
          // Optionally, hide the invoice again
          invoiceRef.current.classList.add('hidden');
        }, 100); // Adjust the delay if necessary
      }
    }}
  >
    <FaDownload size={24} /> {/* Icon for Download */}
  </button>
  
  <button
    className="text-green-500 hover:text-green-700 ml-3"
    onClick={() => {
      if (selectedSessions.length === 0) {
        setErrMsg('Please select at least one session to print the invoice.');
      } else {
        setErrMsg('');
        handleShareOrDownloadPDF();
        onClose();
      }
    }}
  >
    <FaShareAlt size={24} /> {/* Icon for Share */}
  </button>
  
</div>
        {/* Error Message */}
        {errMsg && <p className="text-red-500 mt-4 text-center font-semibold">{errMsg}</p>}
      </div>
    </div>
  );
};

export default PrintInvoiceModal;
