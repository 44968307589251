import React, { useState ,useEffect} from 'react';
import TimeSelectorComponent from '../components/calendar/time_selector_component';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import AddTraineeModal from '../components/trainees/add_trainee';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WeeklySessionsChart from "../components/charts/weekly_session_chart";
import { Modal } from '@mui/material';
import { IoIosAdd} from "react-icons/io";
import { IoEye,IoAdd } from "react-icons/io5";
import { useGetDashboardQuery } from "../redux/api/sessionApi";
import AddExpensesModal from "../components/expenses/add_expenses";
import {Button } from '@mui/material';
import AddSessionModal from '../components/dashboard/add_sessions';
import AddPaymentModal from "../components/payments/add_payment_modal";
import { CircularProgress} from '@mui/material';
import Invoice from '../components/how/invoice';
import Statement from '../components/how/statement';
import WeeklyPaymentsChart from "../components/charts/weekly_payments_chart";
import InvoicePic from '../assets/invoice.png';
import People from '../assets/people.png';
import { FaFileInvoiceDollar } from 'react-icons/fa';

const DashboardPage = () => {
  const [startDate, setStartDate] = useState(dayjs().startOf('day'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [endDate, setEndDate] = useState(dayjs().startOf('day'));
  const [page,setPage]=useState(1);
  const [pending, setPending] = useState([]);
  const [isTraineeModalOpen, setIsTraineeModalOpen] = useState(false);
  const [isIncomesModalOpen, setIsIncomesModalOpen] = useState(false);
  const [isExpensesModalOpen, setIsExpensesModalOpen] = useState(false);
  const [isSessionsModalOpen, setIsSessionsModalOpen] = useState(false);
  const [isStatementModalOpen, setIsStatementModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false); 
  const resetPaginationVars = () => {
    setPage(1);
  }
  const {
    data: dashboardResponse,
    isLoading: isdashboardLoading,
    error: dashboardError,
  } = useGetDashboardQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    page,
    limit: 3,
  });
  useEffect(() => {


    if (page == 1) {
      setPending(dashboardResponse?.pending_users);
    } else {
      setPending((oldPending) => [...oldPending, ...dashboardResponse?.pending_users]);
    }
    setIsFetchingMore(false);  
  }, [dashboardResponse]);
  

  const handleCloseModal = () => {
    setIsTraineeModalOpen(false);
    setIsExpensesModalOpen(false);
    setIsSessionsModalOpen(false);
    setIsStatementModalOpen(false);
    setIsInvoiceModalOpen(false);
  };

  const totalIncomes = Number(dashboardResponse?.total_sum_incomes) || 0; 
const totalExpenses = Number(dashboardResponse?.total_sum_expenses) || 0;

const total = (totalIncomes - totalExpenses);
// const totalIncomesNumber = Number(dashboardResponse?.total_incomes_count||0);
// const totalExpensesNumber = Number(dashboardResponse?.total_expenses_count||0);
const totalTraineesNumber = Number(dashboardResponse?.total_trainees_count||0);
const totalSessionsNumber = Number(dashboardResponse?.total_sessions_count||0);
// const totalPaidSessionsNumber = Number(dashboardResponse?.total_paid_sessions||0);
// const totalPendingSessionsNumber = Number(dashboardResponse?.total_pending_sessions||0);
const totalPaidSessionsPrice = Number(dashboardResponse?.total_paid_price||0);
const totalPendingSessionsPrice = Number(dashboardResponse?.total_pending_price||0);
// const totalSessionsPrice=totalPaidSessionsPrice+totalPendingSessionsPrice;

  
  
  return (
    <div className='flex flex-col items-center justify-center'>
    <div >
      <div className="flex flex-col items-center">
    <div className="w-full max-w-md mb-6 px-4 lg:ml-20">
      <TimeSelectorComponent 
        setStartDate={setStartDate} 
        setEndDate={setEndDate} 
        onDateChanged={() => {}} 
        className="text-sm" 
      />
    </div>
  
    <div className="grid grid-cols-2 sm:grid-cols-2 gap-6 w-[380px] lg:w-[550px] max-w-4xl px-3 lg:px-4  lg:ml-32">
      <div className="bg-blue-200 p-4 rounded-lg shadow-lg flex flex-col items-center text-center">
        <p className="text-lg font-bold">Total Trainees</p>
        <p className="text-4xl font-bold mt-2">{totalTraineesNumber}</p>
        <button className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-lg text-sm" onClick={() => setIsTraineeModalOpen(true)}>
          + Register a Trainee
        </button>
      </div>
  
  
      <div className="bg-blue-300 p-4 rounded-lg shadow-lg flex flex-col items-center text-center">
        <p className="text-lg font-bold">Total Sessions</p>
        <p className="text-4xl font-bold mt-2">{totalSessionsNumber}</p>
        <button className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-lg text-sm" onClick={() => setIsSessionsModalOpen(true)}>
          + Log a Session
        </button>
      </div>
  
  
      <div className="bg-green-300 p-4 rounded-lg shadow-lg flex flex-col items-center text-center">
        <p className="text-lg font-bold">Total Income</p>
        <p className="text-4xl font-bold mt-2">{totalIncomes}$</p>
        <button className="mt-4 bg-green-600 text-white px-4 py-2 rounded-lg text-sm" onClick={() => setIsIncomesModalOpen(true)}>
          + Log a Payment
        </button>
      </div>
  
  
      <div className="bg-yellow-200 p-4 rounded-lg shadow-lg flex flex-col items-center text-center">
        <p className="text-lg font-bold">Total Profit</p>
        <p className="text-4xl font-bold mt-2">{total}$</p>
        <button className="mt-4 bg-yellow-600 text-white px-4 py-2 rounded-lg text-sm" onClick={() => setIsExpensesModalOpen(true)}>
          + Log an Expense
        </button>
      </div>
  
  
      <div className="col-span-2 bg-red-200 p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
        <p className="text-xl font-bold">Pending Payments</p>
        <div className="w-full flex justify-center space-x-10 mt-4 text-xl">
          <div className="text-left">
          <img src={People} alt="receipt icon" className="w-12 h-10 ml-3" />
            
          </div>
          <div className="text-right flex items-center space-x-2">
    <p>BALANCE</p>
  </div>
        </div>
  
        <div className="w-full flex justify-center space-x-10 mt-4 text-xl">
        <div className="flex flex-col items-center gap-y-4 py-4">
                  {isdashboardLoading && page === 1 ? (
                    <div>Loading...</div>
                  ) : pending?.length === 0 && page === 1 ? (
                    <div className="text-center text-sm">You have no pending payments scheduled for this date</div>
                  ) : (
                    <>
                      {pending?.map((pending, index) => (
                        <div key={index} className="flex justify-between items-center space-x-8 lg:space-x-10 ml-10 text-lg lg:text-xl lg:ml-20">
                      <p>
                        <span className="mr-2">{pending.trainee?.firstname}</span>
                        <span>{pending.trainee?.lastname}</span>
                      </p>
                        <div className="flex items-center space-x-3">
                       <p className='border border-black bg-red-400 rounded-lg py-2 px-2'>
                        {pending?.balance != null ? Number(pending.balance).toFixed(2) : '0.00'}$
                      </p>
                          <IoEye
                            size={30}
                            color="#243784"
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(`/traineeaccount/${pending.trainee?.trainee_id}`);
                            }} 
                          />
                        </div>
                      </div>
                      
                      ))}
                      {dashboardResponse?.current_page < dashboardResponse.pages && (
                        <button 
                            className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
                            onClick={() => {
                              setPage((page) => page + 1);
                              setIsFetchingMore(true);
                            }}
                          >
                            {
                              isFetchingMore ?
                              <CircularProgress size={23} sx={{ color: 'white' }} />
                              : 
                              'Load More'
                            }
                          </button>
                      )}                  </>
                  )}
                  {isdashboardLoading && page > 1 && <CircularProgress />}
                </div>
        </div>
    
      </div>
    </div>
  </div>
  <div className="flex flex-wrap justify-between  lg:ml-32 ">
    <div className="w-full sm:w-1/2 p-2">
      <p className="my-5 font-semibold text-center">
        Nb. of Sessions in the last 7 days:
      </p>
      <div className="pointer-events-none lg:pointer-events-auto">  
        <WeeklySessionsChart data={dashboardResponse?.nb_of_sessions_lastweek} />
      </div>
     
    </div>
    <div className="w-full sm:w-1/2 p-2">
      <p className="my-5 font-semibold text-center">
        Payments Amounts in the last 7 days in USD ($):
      </p>
      <div className="pointer-events-none lg:pointer-events-auto"> 
      <WeeklyPaymentsChart data={dashboardResponse?.amount_of_payments_lastweek} />
      </div>
    </div>
  </div>
  <h1 className='text-center lg:ml-32 text-3xl font-bold'>FAQs</h1>
  <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-5 mt-5 lg:ml-20">
    <div className="bg-teal-200 flex items-center justify-between px-2 py-2 rounded-md shadow-md cursor-pointer hover:bg-teal-300 transition-all" onClick={() => setIsStatementModalOpen(true)}>
      <p className="font-semibold text-lg text-gray-900">How to Send a Statement of Account</p>
      <FaFileInvoiceDollar
        size={25}
        color="green"
        className="ml-2 cursor-pointer"
      />
    </div>
  
    <div className="bg-teal-200 flex items-center justify-between px-4 py-2 rounded-md shadow-md cursor-pointer hover:bg-teal-300 transition-all" onClick={() => setIsInvoiceModalOpen(true)}>
      <p className="font-semibold text-lg text-gray-900">How to Send a Receipt</p>
      <img src={InvoicePic} alt="receipt icon" className="w-6 h-6 ml-3" />
    </div>
  </div>
  
  
  
  </div>
  
  
    <Modal open={isTraineeModalOpen} onClose={handleCloseModal} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
      <div>
        <AddTraineeModal onClose={handleCloseModal} />
      </div>
    </Modal>
  
    <Modal open={isIncomesModalOpen} onClose={() => setIsIncomesModalOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
      <div>
        <AddPaymentModal onClose={() => setIsIncomesModalOpen(false)} resetPaginationVars={resetPaginationVars} />
      </div>
    </Modal>
  
    <Modal open={isExpensesModalOpen} onClose={() => setIsExpensesModalOpen(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
      <div>
        <AddExpensesModal onClose={handleCloseModal}  />
      </div>
    </Modal>
  
    <Modal open={isSessionsModalOpen} onClose={handleCloseModal} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
      <div>
        <AddSessionModal onClose={handleCloseModal} />
      </div>
    </Modal>
    <Modal open={isStatementModalOpen} onClose={handleCloseModal} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
      <div>
        <Statement onClose={handleCloseModal} />
      </div>
    </Modal>
    <Modal open={isInvoiceModalOpen} onClose={handleCloseModal} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
      <div>
        <Invoice onClose={handleCloseModal} />
      </div>
    </Modal>
  </div>
  );
};

export default DashboardPage;
