
import React from "react";
import { AiOutlineDollarCircle } from "react-icons/ai";

const IncomeBox = ({income}) => {
  return (
       <div className='max-w-96 w-full border gap-x-5  px-4 py-2 rounded-lg flex justify-between shadow-md'>        
        <div className='flex flex-1 items-center gap-x-3'>
          <div>
            <p className='font-semibold'>{income.trainee.firstname} {income.trainee.lastname}</p>
            <div className='flex gap-x-2 items-center'>
              <AiOutlineDollarCircle color='gray' size={20} />
              <p className='text-green-600 font-semibold text-md'>{income.amount_formatted}</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default IncomeBox