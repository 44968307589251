import React from 'react';
import { IoIosAdd } from 'react-icons/io';
import NormalInput from '../inputs/normal_input/normal_input';
import { MdDeleteOutline } from 'react-icons/md';
import { TextField } from '@mui/material';

const SubTraineesBox = ({ subtrainees, setSubtrainees }) => {
  
  // Function to add a new input
  const addInput = () => {
      setSubtrainees([...subtrainees, '']); 
  };

  // Handler to remove a specific input
  const removeInput = (indexToRemove) => {
      setSubtrainees(subtrainees.filter((_, index) => index !== indexToRemove));
  };

  // Handler to update the subtrainee value
  const updateSubtrainee = (indexToUpdate, value) => {
      const updatedSubtrainees = subtrainees.map((subtrainee, index) =>
          index === indexToUpdate ? value : subtrainee
      );
      setSubtrainees(updatedSubtrainees);
  };

  return (
    <div>
      <div className='flex items-center space-x-4'>
  <label className='font-semibold text-lg'>Trainees</label>
  <div className='bg-main h-6 w-6 hover:opacity-80 cursor-pointer text-white text-lg rounded-full flex items-center justify-center'>
    <IoIosAdd size={20} onClick={addInput} />
  </div>
  <p className='text-sm italic font-normal'>Trainees Under This Account</p>
</div>

        {
            subtrainees.map((subtrainee, index) => (
                <div key={index} className='flex items-center space-x-2 my-3'>
                    <TextField
                      value={subtrainee} 
                      placeholder="Enter subtrainee name"
                      onChange={(e) => updateSubtrainee(index, e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    <MdDeleteOutline
                        size={25}
                        onClick={() => removeInput(index)}
                        style={{ cursor: "pointer" }}
                    />
                </div>
            ))
        }
    </div>
  );
}

export default SubTraineesBox;
