
import React ,{useEffect,useRef} from 'react';
import { IoIosClose } from 'react-icons/io';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setShowAddPaymentModal } from '../../redux/storeSlice';

const AddPaymentModal = ({ onClose, resetPaginationVars, transaction_id }) => {
  const addButtonRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddPayment = async () => {
    navigate('/payment');
    dispatch(setShowAddPaymentModal(false));

    setTimeout(() => {
      const button = document.getElementById('add_payment');
      if (button) {
          button.click();
      }
  }, 300); 
  };

  return (
    <div
  className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-20" id="div"
  onClick={onClose}
>
  <div
    className="relative mx-4 px-8 py-6 w-[350px] max-w-full overflow-y-auto bg-white rounded-2xl shadow-lg transform transition-all duration-300 ease-in-out"
    onClick={(e) => e.stopPropagation()}
  >
    <div className="flex justify-between items-center mb-4">
      <p className="font-bold text-xl text-gray-800">Add Payment ?</p>
      <IoIosClose
        size={30}
        className="text-gray-500 cursor-pointer hover:text-red-500 transition-colors duration-200"
        onClick={() => onClose()}
      />
    </div>
    <div className="flex justify-end mt-5">
    <FormSubmitBtn
        text="Add Payment"
        onClick={handleAddPayment}
        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-200 ease-in-out"
      />
    </div>
  </div>
</div>

  );
};

export default AddPaymentModal;
