import dayjs from "dayjs";
import { apiSlice } from "./apiSlice";

export const paymentSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // Create Payment (POST)
        createPayment: builder.mutation({
            query: (newPayment) => ({
                url: 'coach/transaction',
                method: 'POST',
                body: newPayment,
            }),
            invalidatesTags: (result, error, { traineeId }) => [
                { type: 'Payment' },
                { type: 'Incomes' },
                { type: 'Sessions' },
                { type: 'Dashboard' },
                { type: 'Trainee', id: 'LIST' },{ type: 'trainee_by_id', traineeId },
                { type: 'payments_by_user', traineeId }
              ],
        }),
        getPayments: builder.query({
            query: ({startDate, endDate, limit, page}) => {
                const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
                const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
                return `https://control.clickcoach.app/index.php?route=rest/coach/transaction&date_start=${formattedStartDate}&date_end=${formattedEndDate}&page=${page}&limit=${limit}`;
            },
            transformResponse: (response) => response.data,
            providesTags: ['Payment'],
            invalidatesTags: (result, error, { traineeId }) => [
                { type: 'payments_by_user', traineeId },
                { type: 'Payment' },
                { type: 'Incomes' },
                { type: 'Dashboard' },
                { type: 'Trainee', id: 'LIST' },
                { type: 'Sessions' },{ type: 'trainee_by_id', traineeId }
              ],  
        }),
        getPaymentsByUser: builder.query({
            query: ({traineeId, page, limit}) => 
                `https://control.clickcoach.app/index.php?route=rest/coach/transactions_by_trainee&trainee_id=${traineeId}&page=${page}&limit=${limit}`,
            transformResponse: (response) => response.data,
            providesTags: (result, error, { traineeId }) => [{ type: 'payments_by_user', traineeId }],
            invalidatesTags: (result, error, { traineeId }) => [
                { type: 'payments_by_user', traineeId },
                { type: 'Payment' },
                { type: 'Incomes' },
                { type: 'Dashboard' },
                { type: 'Trainee', id: 'LIST' },
                { type: 'Sessions' },{ type: 'trainee_by_id', traineeId }
              ],  
        }),
        deletePayment: builder.mutation({
            query: (transaction_id) => ({
              url: `coach/transaction/${transaction_id}`,
              method: 'DELETE',
            }),
            invalidatesTags: (result, error, { traineeId }) => [
                { type: 'payments_by_user', traineeId },
                { type: 'Payment' },
                { type: 'Incomes' },
                { type: 'Dashboard' },
                { type: 'Trainee', id: 'LIST' },
                { type: 'Sessions' },{ type: 'trainee_by_id', traineeId }
              ],            
          }),
          editPayments: builder.mutation({
            query: (payment) => ({
              url: `coach/transaction/${payment.transaction_id}`,
              method: 'PUT',
              body: payment,
            }),
            invalidatesTags: (result, error, { payment ,traineeId}) => [
                { type: 'payments_by_user', payment },
                { type: 'Payment' },
                { type: 'Incomes' },
                { type: 'Dashboard' },
                { type: 'Trainee', id: 'LIST' },
                { type: 'Sessions' },
                { type: 'sessions_by_user', traineeId },{ type: 'trainee_by_id', traineeId }
              ],    
          }),
    })
})

export const { useCreatePaymentMutation, useDeletePaymentMutation, useGetPaymentsByUserQuery, useGetPaymentsQuery,useEditPaymentsMutation } = paymentSlice;