import React, { useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import { useDeletePaymentMutation, useEditPaymentsMutation } from '../../redux/api/paymentSlice';
import { getMessageFromError } from '../../utils/utils';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

const EditPaymentModal = ({ onClose, resetPaginationVars, payment }) => {
  const [errMsg, setErrMsg] = useState('');
  const [amount, setAmount] = useState(payment.amount);
  const [note, setNote] = useState(payment.note || '');
  const [editPayments, { isLoading: isEditLoading }] = useEditPaymentsMutation();
  const [deletePayment, { isLoading }] = useDeletePaymentMutation();
  const [date, setDate] = useState(payment?.date_added ? dayjs(payment.date_added) : dayjs());

  const handleDeletePayment = async () => {
    try {
      await deletePayment(payment.transaction_id).unwrap();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  const handleEditPayments = async () => {
    try {
      await editPayments({
        transaction_id: payment.transaction_id,
        trainee_id:payment.trainee.trainee_id,
        amount,
        note,
        date_added:date,
        date:date
      }).unwrap();
      resetPaginationVars();
      onClose();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };


  return (
    <div
    className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-sm"
    onClick={onClose}
  >
    <div
      className="relative mx-4 px-8 py-6 w-[400px] max-w-full overflow-y-auto bg-white rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out"
      onClick={(e) => e.stopPropagation()}
    >

      <div className="flex justify-between items-center mb-6 pb-2 border-b border-gray-300">
        <h2 className="font-bold text-2xl text-gray-900">Edit Payment</h2>
        <IoIosClose
          size={35}
          className="text-gray-500 cursor-pointer hover:text-red-500 transition-colors duration-200"
          onClick={onClose}
        />
      </div>
  
              <div className="flex items-center justify-start mb-4">
          <label className="text-gray-700 font-semibold w-1/3">Trainee Name</label>
          <p className="font-bold text-lg text-blue-800 w-2/3">
            {payment.trainee.firstname} {payment.trainee.lastname}
          </p>
        </div>

      <div className="flex items-center justify-between mb-4">
        <label className="text-gray-700 font-semibold">Amount</label>
        <input
          type="number"
          className="border border-gray-300 rounded-lg px-3 py-2 w-2/3 focus:ring-2 focus:ring-indigo-500 focus:outline-none shadow-sm transition-shadow duration-200"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount"
        />
      </div>
  
      {note !== undefined && (
        <div className="flex items-center justify-between mb-4">
          <label className="text-gray-700 font-semibold">Note</label>
          <input
            className="border border-gray-300 rounded-lg px-3 py-2 w-2/3 focus:ring-2 focus:ring-indigo-500 focus:outline-none shadow-sm transition-shadow duration-200"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Add a note"
          />
        </div>
      )}
   <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className='flex gap-x-3'>
                                    <article>
                    <label className='font-semibold'>Date</label>
                    <div className='h-2'></div>
                    <div>
                        <DatePicker
                            label=""
                            value={date}
                            onChange={(newValue) => setDate(newValue)}
                            slotProps={{ textField: { fullWidth: true } }}
                            maxDate={dayjs()} 
                        />
                    </div>
                </article>
                        
                    </div>
                </LocalizationProvider>

      <div className="flex justify-between items-center mt-6">
        <FormSubmitBtn
          text="Edit"
          isLoading={isEditLoading}
          onClick={handleEditPayments}
        />
        <FormSubmitBtn
        text="Delete"
        isLoading={isLoading}
        onClick={() => {handleDeletePayment(); onClose()}} 
        className="bg-red-500 hover:bg-red-600 text-white  rounded-lg transition-all duration-200 ease-in-out"
    />
      </div>
      {errMsg && (
        <p className="text-red-500 mt-4 text-center font-semibold">{errMsg}</p>
      )}
    </div>
  </div>
  
  );
};

export default EditPaymentModal;
