import { useState, useEffect, useRef } from 'react';
import { FaUser } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { Dialog, DialogPanel, PopoverButton, PopoverPanel, Popover } from '@headlessui/react';
import { profileService } from '../../services/profile_services';
import PageTitle from './page_title';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { authService } from '../../services/auth_services';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from '../../redux/userSlice';
import profilePic from '../../assets/profile_pic.jpg';
import { FaPen } from "react-icons/fa"
import Dashboard from '../../assets/dashboard.png';
import Sessions from '../../assets/sessions.png';
import People from '../../assets/people.png';
import Payments from '../../assets/payments.png';
import Profit from '../../assets/profit.png';
import './header.css';

const buttons = [
  { title: 'Dashboard', route: '/dashboard', image: Dashboard },
  { title: 'Sessions', route: '/sessions', image: Sessions },
  { title: 'My Trainees', route: '/trainees', image: People },
  { title: 'Payments', route: '/payment', image: Payments },
  { title: 'Profit', route: '/profit', image: Profit },
];
  
export default function Header() {
  const [loading, setLoading] = useState(true);
  const [activeRoute, setActiveRoute] = useState('/');
  const [error, setError] = useState(null);
  const user = useSelector(state => state.user.data);
  const [profile, setProfile] = useState({ firstname: '', lastname: ''});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profileData] = await Promise.all([
          profileService.getProfile()
        ]);

        setProfile({
          firstname: profileData.firstname,
          lastname: profileData.lastname,
        });
        
      } catch (err) {
        setError('Failed to fetch user details');
        console.error('Error fetching user details: ', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  const logout = async () => {
    await authService.logout();
    dispatch(logOutUser());
    navigate('/');
  };

  return (
    <header>
   <div className="hidden sm:block text-xl mt-5 text-center ml-32">
  <span className="bg-gradient-to-r from-blue-600 to-blue-900 text-white font-bold rounded-lg px-6 py-3 shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
    Welcome {profile.firstname} {profile.lastname}
  </span>
</div>

  
    <nav className="mx-auto w-full flex max-w-7xl items-center justify-between p-3 lg:p-6 lg:px-8 lg:bg-white bg-black border-b border-black rounded-b-3xl lg:border-none lg:py-0 py-4 lg:mb-5 lg:mt-5">
      
      <div className="flex flex-1 justify-start items-center">
      
        <img
          className="w-10 h-10 md:w-12 md:h-12 rounded-md"
          src="/logo.png"
          alt="Logo"
          onClick={() => navigate('/dashboard')}
        />

      </div>
  
      <div className="flex justify-center items-center">
      <div className="text-white">
    Welcome <span className='font-bold text-xl'>{profile.firstname} {profile.lastname}</span>
  </div>
</div>

      <div className="hidden lg:flex navbarr max-h-80">
        {buttons.map((btn, index) => (
          <PageTitle
            key={index}
            button={btn}
            image={btn.image}
            isActive={btn.route === activeRoute}
            onClick={() => setActiveRoute(btn.route)}
          />
        ))}
        <div className="animation start-home"></div>
      </div>
  
     
      <div className="flex flex-1 justify-end items-center">
        <Popover className="relative">
        <PopoverButton className="focus:outline-none relative">
  <img
    className="w-10 h-10 md:w-12 md:h-12 rounded-full cursor-pointer border-2 border-gray-500" // Optional: Add border if needed
    src={user?.image ? user.image : profilePic}
    alt="User Profile"
  />
  <span className="absolute bottom-0 right-0 bg-white rounded-full ">
    <FaPen className="text-gray-600" size={13}/>
  </span>
</PopoverButton>
<PopoverPanel anchor="bottom" className="rounded-md mt-1 flex flex-col bg-black text-white shadow-lg">
  <Link to="/profile" className="hover:bg-gray-300 px-3 py-2">
    <div className="flex items-center">
      <FaUser />
      <div className="w-2"></div>
      <p>Profile</p>
    </div>
  </Link>
  <div className="bg-gray-200" style={{ height: "1px" }}></div>
  <Link className="hover:bg-gray-300 px-3 py-2" onClick={() => logout()}>
    <div className="flex items-center">
      <FiLogOut color="#ff0000" size={18} />
      <div className="w-2"></div>
      <p className="text-red-500 mt-2">Log Out</p>
    </div>
  </Link>
</PopoverPanel>


        </Popover>
      </div>
    </nav>
  
   
    <div className="lg:hidden flex">
  {buttons.map((btn, index) => (
    <PageTitle
      key={index}
      button={btn}
      image={btn.image}
      isActive={btn.route === activeRoute}
      onClick={() => setActiveRoute(btn.route)}
    />
  ))}
</div>

  </header>
  );
}
