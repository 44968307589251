import React, { useEffect, useState ,useRef} from "react";
import { IoAdd } from "react-icons/io5";
import dayjs from "dayjs";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Modal } from "@mui/material";
import AddPaymentModal from "../components/payments/add_payment_modal";
import { useGetPaymentsQuery } from "../redux/api/paymentSlice";
import PaymentBox from "../components/payments/payment_box";
import { FaArrowUpLong } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import TimeSelectorComponent from "../components/calendar/time_selector_component";
import CustomPieChart from "../components/charts/custom_pie_chart";
import { FaRegHourglass } from "react-icons/fa6";
import LoadingPage from "./loading_page";
import { PAGE_LIMIT } from "../utils/constants";
import WeeklyPaymentsChart from "../components/charts/weekly_payments_chart";
import Log from '../assets/log.png';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const PaymentPage = () => {
  const theme = createTheme();
  const addButtonRef = useRef(null);
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs().startOf("day"));
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false); 
  const {
    data: paymentResponse,
    isLoading: isPaymentsLoading,
    error: paymentsError,
  } = useGetPaymentsQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    page,
    limit: PAGE_LIMIT,
  });


  const resetPaginationVars = () => {
    setPage(1);
  }

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (paymentsError) return;

    if (page == 1) {
      setPayments(paymentResponse?.transactions);
    } else {
      setPayments((prevPayments => [...prevPayments, ...paymentResponse?.transactions]))
    }
    setIsFetchingMore(false);
  }, [paymentResponse]);


  if (isPaymentsLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <div className="flex justify-center w-full">
        <div className="max-w-5xl w-full px-3">
        <section>
  <div className="w-full flex flex-col lg:flex-row space-x-2 lg:mr-0 mx-auto mt-4 lg:mt-0">
    <p className="flex items-center text-lg font-bold mb-4 mx-auto lg:mx-0">
      Payments Log
      <img src={Log} className="w-5 h-6 ml-2" />
    </p>

    <div className="mt-1 mx-auto text-center lg:mx-0 lg:mb-5 flex justify-center items-center">
  <div
    id="add_payment"
    className="w-6 h-6 bg-green-500 flex items-center cursor-pointer hover:opacity-80 justify-center rounded-full"
    onClick={() => setIsModalOpen(true)}
  >
    <IoAdd color="white" size={20} />
  </div>
</div>

  </div>

  <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 lg:grid-cols-4 mt-3 justify-center mx-auto">
    <div className="flex justify-center lg:justify-end">
      <div className="flex items-center bg-red-200 border rounded-lg px-1">
        <div className="w-8">
          <FaRegHourglass color="orange" size={16} />
        </div>
        <p>Total Pending: </p>
        <p className="ml-2 text-orange-500">
          {paymentResponse?.total_pending_amount_formatted}
        </p>
      </div>
    </div>

    <div className="flex justify-center lg:justify-end">
      <div className="flex items-center bg-red-200 py-1 border rounded-lg px-3">
        <div className="w-8">
          <FaUsers color="orange" size={23} />
        </div>
        <p>Pending Users: </p>
        <p className="ml-2 text-orange-500">
          {paymentResponse?.total_pending_users}
        </p>
      </div>
    </div>

    <div className="flex justify-center lg:justify-end">
      <div className="flex items-center bg-green-300 py-1 border rounded-lg px-2">
        <div className="w-8">
          <FaArrowUpLong color="green" size={16} />
        </div>
        <p>Total Income: </p>
        <p className="ml-2 text-green-600">
          {paymentResponse.total_income_formatted}
        </p>
      </div>
    </div>

    <div className="flex justify-center lg:justify-end">
      <div className="flex items-center bg-green-300 py-1 border rounded-lg px-2">
        <div className="w-8">
          <FaUsers color="green" size={23} />
        </div>
        <p>Total Users: </p>
        <p className="ml-2 text-green-600">
          {paymentResponse.total_trainees}
        </p>
      </div>
    </div>
  </div>
</section>


          <section className="my-10 flex flex-col lg:flex-row">
            <aside className="flex flex-col md:items-center md:flex-row lg:flex-col flex-1 justify-center md:justify-around lg:justify-start">
              <div className="flex flex-col w-full md:flex-row md:justify-around lg:flex-col lg:justify-start">
                <div className="hidden md:block">
                  <p className="font-semibold my-5">
                    Pending Amounts by Users:
                  </p>
                  <CustomPieChart
                  
                    data={paymentResponse?.pending_users?.map(
                      (transaction) => ({
                        value: transaction.balance * -1,
                        label: `${transaction.trainee?.firstname} ${transaction.trainee?.lastname}`,
                        displayValue: transaction.balance_formatted,
                        color: transaction.trainee?.color,
                      })
                    )}
                  />
                </div>

                <div className="hidden md:block">
                  <p className="my-5 font-semibold">
                    Pending Sessions by Users:
                  </p>
                  <CustomPieChart
                    data={paymentResponse?.pending_users?.map(
                      (transaction) => ({
                        value: transaction.pending_sessions,
                        label: `${transaction.trainee?.firstname} ${transaction.trainee?.lastname}`,
                        color: transaction.trainee?.color,
                        displayValue: transaction.pending_sessions,
                      })
                    )}
                  />
                </div>
              </div>
            </aside>
            <main className="w-full flex-1 lg:mt-0">
            <aside className="flex flex-col items-start md:items-center md:flex-row lg:flex-col flex-1 justify-start md:justify-around lg:justify-start ml-4 md:ml-16 lg:ml-32">
  <div className="flex flex-col w-full md:flex-row md:justify-around lg:flex-col lg:justify-start mb-3">
    <div>
      <p className="my-5 mb-0 font-semibold text-sm md:text-base lg:text-lg">
      Payments Amounts in the last 7 days in USD ($):
      </p>
      <div className="pointer-events-none lg:pointer-events-auto">
    <WeeklyPaymentsChart data={paymentResponse?.amount_of_payments_lastweek} />
</div>

    </div>
  </div>
</aside>
            <div className="text-sm lg:text-lg mt-5">
            <TimeSelectorComponent
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                onDateChanged={() => resetPaginationVars()}
              ></TimeSelectorComponent>
            </div>
              <div className="flex items-center justify-center flex-col mt-7 gap-y-4"> 
                {payments?.length > 0 ? (
                  <>
                    <div className="w-full max-w-96">
                      <p className="text-start font-semibold">Total transactions: {paymentResponse?.total_transactions}</p>
                    </div>
                    {payments?.map((transaction, index) => (
                      <PaymentBox key={index} payment={transaction} />
                    ))}
                    {
                      paymentResponse?.current_page < paymentResponse?.pages && 
                      <button 
                        className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
                        onClick={() => {
                          setPage((page) => page + 1);
                          setIsFetchingMore(true);
                        }}
                      >
                        {
                          isFetchingMore ?
                          <CircularProgress size={23} sx={{ color: 'white' }} />
                          : 
                          'Load More'
                        }
                      </button>
                    }
                  </>
                ) : (
                  <p className="text-sm">
                    No payments have been made in this date
                  </p>
                )}
              </div>
            </main>
          </section>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div>
          <AddPaymentModal 
            onClose={() => setIsModalOpen(false)} 
            resetPaginationVars={resetPaginationVars}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PaymentPage;
