import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Modal} from "@mui/material";
import { useGetSessionsByUserQuery } from "../redux/api/sessionApi";
import { PAGE_LIMIT } from "../utils/constants";
import SessionBox from "../components/sessions/session_box";
import { useGetPaymentsByUserQuery } from "../redux/api/paymentSlice";
import PaymentBox from "../components/payments/payment_box";
import { IoArrowBack } from "react-icons/io5";
import FormSubmitBtn from "../components/buttons/form_submit_btn/form_submit_btn";
import { IoEye,IoAdd } from "react-icons/io5"; 
import AddSessionModal from '../components/sessions/add_session_modal';
import { IoIosAdd } from "react-icons/io";
import EditTraineeModal from "../components/trainees/edit_trainee";
import PrintInvoiceModal from "../components/trainees/print_invoice";
import Statement from "../components/trainees/statement";
import { FaFileInvoiceDollar } from 'react-icons/fa';
import {Button } from '@mui/material';
import { FaPrint } from 'react-icons/fa';
import { useGetTraineeByIdQuery } from '../redux/api/traineeApi';
import {
  useDeleteTraineeMutation,
} from "../redux/api/traineeApi";
import { getMessageFromError } from "../utils/utils";
import ErrorPopup from "../components/common/error_popup";
import AddSession from '../components/trainees/add_session';
import AddPayment from "../components/trainees/add_payment";
import InvoicePic from '../assets/invoice.png';
import StatementPic from '../assets/statement.png';


const TraineeAccountPage = () => {
  const [trainee, setTrainee] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: traineeResponse, error, isLoading } = useGetTraineeByIdQuery(id);
  const [balance, setBalance] = useState([]);
  const [isSessionModalOpen, setIsSessionModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isStatementModalOpen, setIsStatementModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("sessions");
  const [sessions, setSessions] = useState([]);
  const [isEditSessionModalOpen, setIsEditSessionModalOpen] = useState(false);
  const [sessionsPage, setSessionsPage] = useState(1);
  const [isFetchingMoreSessions, setIsFetchingMoreSessions] = useState(1);
  const { data: sessionsResponse, isSessionsLoading } =
    useGetSessionsByUserQuery({
      traineeId: id,
      page: sessionsPage,
      limit: PAGE_LIMIT,
    });
    const resetPaginationVars = () => {
      setPage(1);
    }
  const [payments, setPayments] = useState([]);
  const [paymentsPage, setPaymentsPage] = useState(1);
  const [isFetchingMorePayments, setIsFetchingMorePayments] = useState(1);
  const [errMsg, setErrMsg] = useState("");

  const { data: paymentsResponse, isPaymentsLoading } =
    useGetPaymentsByUserQuery({
      traineeId: id,
      page: paymentsPage,
      limit: PAGE_LIMIT,
    });
    useEffect(() => {
      
      if (!traineeResponse) return;
  
        setBalance(traineeResponse?.data.balance)    
    }, [traineeResponse]); 

    useEffect(() => {
      if (!traineeResponse) return;
  
        setTrainee(traineeResponse?.data.trainee)      
    
    }, [traineeResponse]); 
    

  
    useEffect(() => {
  if (sessionsResponse?.sessions) {
    setIsFetchingMoreSessions(false);
    if (sessionsPage === 1) {
      setSessions(sessionsResponse.sessions || []);
    } else {
      setSessions((oldSessions) => [
        ...oldSessions,
        ...sessionsResponse.sessions,
      ]);
    }
  } else {
    setSessions([]);
  }
}, [sessionsResponse, sessionsPage]);

  useEffect(() => {
    if (paymentsPage == 1) {
      setPayments(paymentsResponse?.transactions);
    } else {
      setPayments((oldPayments) => [
        ...oldPayments,
        ...paymentsResponse.transactions,
      ]);
    }
    setIsFetchingMorePayments(false);
  }, [paymentsResponse]);

  const handleRowClick = (session) => {
    setSelectedSession(session);
    setIsEditSessionModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsEditSessionModalOpen(false);
    setSelectedSession(null);
  };
  const [deleteTrainee, { isLoading: isDeleteLoading }] =
    useDeleteTraineeMutation();

  const handleDeleteTrainee = async () => {
    try {
      await deleteTrainee(id).unwrap();
      navigate(-1);
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  return (
    <div className="flex items-center justify-center">
      <section className="max-w-5xl w-full my-9 flex flex-col md:flex-row gap-y-3 lg:shadow-md py-2 lg:py-5 px-2 md:px-6 lg:border">
        <div className="w-12  flex  justify-start pt-1 ">
          <IoArrowBack
            size={22}
            color="#243784"
            className="cursor-pointer"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="w-full px-2 lg:pl-0">
          <p className="text-xl lg:text-xl font-bold mb-6">Account Details</p>
          <p className="font-bold text-lg text-blue-800 text-center flex items-center justify-center space-x-2">
  <span>
    {trainee?.firstname}   {trainee?.lastname}
  </span>
  <IoEye
    size={30}
    color="#243784"
    className="cursor-pointer"
    onClick={() => setIsModalOpen(true)}
  />
</p>

              <div className="flex items-center justify-center mb-5">
      <div className="flex items-center">
      <div className="flex justify-start mt-10 items-center">
  <label className="font-semibold text-lg ml-10">
    Balance: 
  </label>
  <div
    className={`font-semibold text-lg ml-2 ${
      balance > 0 ? "text-green-600" : balance < 0 ? "text-red-500" : "text-black"
    }`}
  >
    {Number(balance).toFixed(2)}$
  </div>
</div>
      </div>
</div>
<div className="flex items-center justify-center cursor-pointer"  onClick={() => setIsStatementModalOpen(true)}>
  <div className="inline-flex items-center justify-center mb-5 border rounded-lg bg-blue-900 px-4 py-2 text-white">
    <span style={{ fontWeight: "bold", fontSize: "18px" }}>
      SEND STATEMENT OF ACCOUNT
    </span>
    <FaFileInvoiceDollar
      size={25}
      color="green"
      className="ml-2 cursor-pointer"
      onClick={() => setIsStatementModalOpen(true)}
    />
  </div>
</div>

          <div className="max-w-5xl mx-auto bg-white p-4 rounded-lg shadow-md border border-gray-100">
  <div className="flex justify-center mb-4">
    <button
      className={`mx-2 px-4 py-2 ${
        activeTab === "sessions"
          ? "font-bold text-red-500 border-b-2 border-red-500"
          : "text-gray-500"
      }`}
      onClick={() => setActiveTab("sessions")}
    >
      SESSIONS
    </button>
    <button
      className={`mx-2 px-4 py-2 ${
        activeTab === "payments"
          ? "font-bold text-green-500 border-b-2 border-green-500"
          : "text-gray-500"
      }`}
      onClick={() => setActiveTab("payments")}
    >
      PAYMENTS
    </button>
  </div>
  <div className="flex items-center justify-center">
        <p style={{fontSize:'18px',fontWeight:'bold'}}>Summary of All Sessions and Payments</p>
        </div>
  <div>
    {activeTab === "sessions" && (
      <section className="my-10">
        <div className="flex  justify-end mr-2"  onClick={() => setIsPrintModalOpen(true)}>
        <div className="inline-flex items-center justify-center mb-5 border rounded-lg bg-blue-900 px-4 py-1 text-white">
    <span style={{ fontWeight: "bold", fontSize: "18px" }}>
      SEND A RECEIPT
    </span>
    <img src={InvoicePic} className="w-7 h-7 ml-2" onClick={() => setIsPrintModalOpen(true)}/>
  </div>

        </div>
                <p className="font-semibold text-lg mb-3 flex items-center">
          Sessions ({sessionsResponse?.total_sessions}) 
          <div className='flex items-center justify-center bg-main text-white cursor-pointer hover:opacity-70 active:opacity-60 w-6 h-6 rounded-full ml-3'>
                        <IoIosAdd size={25} onClick={() => setIsSessionModalOpen(true)} />
                      </div> 
        </p>
        <div className="grid lg:grid-cols-2 justify-center lg:justify-start gap-y-4">
          {sessions?.map((session, index) => (
            <div key={index} onClick={() => handleRowClick(session)}>
              <SessionBox session={session} />
            </div>
          ))}
        </div>
        {sessionsResponse?.current_page < sessionsResponse?.pages && (
          <div className="flex mt-5 justify-center">
            <button
              className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
              onClick={() => {
                setSessionsPage((page) => page + 1);
                setIsFetchingMoreSessions(true);
              }}
            >
              {isFetchingMoreSessions ? (
                <CircularProgress size={23} sx={{ color: "white" }} />
              ) : (
                "Load More"
              )}
            </button>
          </div>
        )}
      </section>
    )}

    {activeTab === "payments" && (
      <section className="my-10">
        <p className="font-semibold text-lg mb-3 flex items-center">
  Payments ({paymentsResponse?.total_transactions}) 
  <div
    className='flex items-center justify-center bg-main text-white cursor-pointer hover:opacity-70 active:opacity-60 w-6 h-6 rounded-full ml-3'
    onClick={() => setIsPaymentModalOpen(true)}
  >
    <IoIosAdd size={25} />
  </div>
</p>

        <div className="grid lg:grid-cols-2 justify-center lg:justify-start gap-y-4">
          {payments?.map((payment, index) => (
            <div key={index}>
              <div className="flex items-center">
                <PaymentBox payment={payment} />
              </div>
            </div>
          ))}
        </div>
        {paymentsResponse?.current_page < paymentsResponse?.pages && (
          <div className="flex mt-5 justify-center">
            <button
              className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
              onClick={() => {
                setPaymentsPage((page) => page + 1);
                setIsFetchingMorePayments(true);
              }}
            >
              {isFetchingMorePayments ? (
                <CircularProgress size={23} sx={{ color: "white" }} />
              ) : (
                "Load More"
              )}
            </button>
          </div>
        )}
        <ErrorPopup
          message={errMsg}
          isVisible={errMsg?.length > 0}
          onClose={() => setErrMsg("")}
        />
      </section>
    )}
  </div>
</div>

          <div className="flex gap-x-3 justify-end mt-5">
            <FormSubmitBtn
              className="bg-red-500"
              onClick={() => handleDeleteTrainee()}
              text="Delete"
              isLoading={isDeleteLoading}
            />
          </div>
        </div>
        
      </section>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div>
          <EditTraineeModal 
            onClose={() => setIsModalOpen(false)} 
            trainee={trainee}
          
          />
        </div>
      </Modal>
      <Modal
        open={isPrintModalOpen}
        onClose={() => setIsPrintModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div>
          <PrintInvoiceModal 
            onClose={() => setIsPrintModalOpen(false)} 
            trainee={trainee}
          />
        </div>
      </Modal>
      <Modal
        open={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div>
          <AddPayment 
            onClose={() => setIsPaymentModalOpen(false)} 
            resetPaginationVars={resetPaginationVars}
          trainee={trainee}/>
        </div>
      </Modal>
      <Modal
            open={isSessionModalOpen}
            onClose={() => setIsSessionModalOpen(false)}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <div>
              <AddSession onClose={() => setIsSessionModalOpen(false)}  resetPaginationVars={resetPaginationVars} trainee={trainee}/>
            </div>
          </Modal>
          <Modal
            open={isEditSessionModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <div>
              <AddSessionModal session={selectedSession} onClose={handleCloseModal}/>
            </div>
          </Modal>
          <Modal
        open={isStatementModalOpen}
        onClose={() => setIsStatementModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div>
          <Statement 
            onClose={() => setIsStatementModalOpen(false)} 
            trainee={trainee}
      
          />
        </div>
      </Modal>
    </div>
  );
};

export default TraineeAccountPage;
