import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { BarChart } from "@mui/x-charts/BarChart";

const WeeklyPaymentsChart = ({ data }) => {
    const [itemData, setItemData] = React.useState();
    const [axisData, setAxisData] = React.useState();

    // Prepare chart data
    const getWidth = () => {
        if (window.innerWidth > 1200) { // You can adjust this breakpoint as needed
            return 500; // Width for large screens
        }
        return 420; // Default width for smaller screens
    };
    
    const barChartsParams = {
        series: [],
        xAxis: [],
        height: 400,
        width: getWidth(),
    };
    
    // If you want to re-evaluate the width on window resize, you can add an event listener
    window.addEventListener('resize', () => {
        barChartsParams.width = getWidth();
    });
    

    if (data) {
        const paymentDays = [];
        const amountsByDay = {};

        data.forEach(item => {
            const date = new Date(item.payment_day);
            const formattedDate = date.toLocaleString("default", { month: "short", day: "numeric" });
            if (!paymentDays.includes(formattedDate)) {
                paymentDays.push(formattedDate);
            }

            if (!amountsByDay[formattedDate]) {
                amountsByDay[formattedDate] = {};
            }

            item.transactions.forEach(transaction => {
                const numericAmount = parseFloat(transaction.amount) || 0; 
              
                if (transaction.firstname && transaction.lastname) {
                    const fullName = `${transaction.firstname} ${transaction.lastname}`;
                    const amountFormatted = transaction.amount_formatted || "$0.00"; 
                    // Initialize the entry for the user's transactions if it doesn't exist
                    if (!amountsByDay[formattedDate][fullName]) {
                        amountsByDay[formattedDate][fullName] = {
                            total: 0,
                            formatted: amountFormatted,  // Store the formatted amount
                        };
                    }
                    amountsByDay[formattedDate][fullName].total += numericAmount;
                }
            });
        });

        const seriesData = {};
        Object.keys(amountsByDay).forEach(day => {
            for (const name in amountsByDay[day]) {
                if (!seriesData[name]) {
                    seriesData[name] = Array(paymentDays.length).fill(0);
                }
                const index = paymentDays.indexOf(day);
                if (index >= 0) {
                    seriesData[name][index] += amountsByDay[day][name].total;
                }
            }
        });

        Object.keys(seriesData).forEach(name => {
            if (seriesData[name].some(amount => amount > 0)) {
                barChartsParams.series.push({
                    id: name,
                    data: seriesData[name], // Numeric data for the y-axis range
                    label: name,
                    stack: "total",
                    highlightScope: {
                        highlight: "item",
                    },
                    tooltip: {
                        // Customize the tooltip to show the formatted amount
                        formatter: (item) => {
                            const dayIndex = item.index;
                            const totalAmount = seriesData[name][dayIndex];
                            const formattedAmount = `$${totalAmount.toFixed(2)}`; // Format amount for display
                            const amountFormatted = amountsByDay[paymentDays[dayIndex]][name]?.formatted || "$0.00"; // Use the formatted amount stored earlier
                            return `${name}: ${formattedAmount} (Formatted: ${amountFormatted})`; // Show formatted amount in tooltip
                        },
                    },
                    labelFormatter: (item) => {
                        const dayIndex = item.index;
                        const amount = seriesData[name][dayIndex];
                        const formattedAmount = amountsByDay[paymentDays[dayIndex]][name]?.formatted || "$0.00"; // Use the formatted amount stored earlier
                        return `${formattedAmount} (${amount})`; // Display formatted amount and numeric total on each bar
                    },
                });
            }
        });
        
        barChartsParams.xAxis.push({
            data: paymentDays,
            scaleType: "band",
            id: "axis1",
        });
    }

    return (
        <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 0, md: 4 }}
            sx={{ width: "100%" }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <BarChart
                    {...barChartsParams}
                    onItemClick={(event, d) => setItemData(d)}
                    onAxisClick={(event, d) => setAxisData(d)}
                />
            </Box>
        </Stack>
    );
};

export default WeeklyPaymentsChart;
