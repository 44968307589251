import React, { useEffect, useState } from "react";
import { CircularProgress, Modal, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useGetIncomesQuery } from "../../src/redux/api/income";
import { useGetExpensesQuery } from "../../src/redux/api/expenses";
import IncomeBox from "../components/incomes/income_box";
import ExpensesBox from "../components/expenses/expenses_box";
import { AiOutlineDollarCircle } from "react-icons/ai";
import AddExpensesModal from "../components/expenses/add_expenses";
import TimeSelectorComponent from "../components/calendar/time_selector_component";
import { PAGE_LIMIT } from "../utils/constants";
import AddPaymentModal from "../components/payments/add_payment_modal";
import { FaArrowUpLong } from "react-icons/fa6";
import { IoAdd } from "react-icons/io5";


const Profit = () => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("income");
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs().startOf("day"));
  const [expensesPage, setExpensesPage] = useState(1);
  const [incomePage, setIncomePage] = useState(1);
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [selectedExpenses, setSelectedExpenses] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data: incomesResponse,
    isLoading: isincomesLoading,
    error: incomesError,
  } = useGetIncomesQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    page: incomePage,
    limit: PAGE_LIMIT,
  });

  const resetPaginationVars = () => {
    setExpensesPage(1);
    setIncomePage(1);
  };

  const handleRowClick = (expenses) => {
    setSelectedExpenses(expenses);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedExpenses(null);
  };

  const {
    data: expensesResponse,
    isLoading: isexpensesLoading,
    error: expensesError,
  } = useGetExpensesQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    page: expensesPage,
    limit: PAGE_LIMIT,
  });

  const [isFetchingIncomesMore, setIsFetchingIncomesMore] = useState(false);
  const [isFetchingExpensesMore, setIsFetchingExpensesMore] = useState(false);

  useEffect(() => {
    if (incomesError) return;

    if (incomePage === 1) {
      setIncomes(incomesResponse?.income);
    } else {
      setIncomes((previncomes) => [
        ...previncomes,
        ...incomesResponse?.income,
      ]);
    }
    setIsFetchingIncomesMore(false);
  }, [incomesResponse]);

  useEffect(() => {
    if (expensesError) return;

    if (expensesPage === 1) {
      setExpenses(expensesResponse?.expenses);
    } else {
      setExpenses((prevexpenses) => [
        ...prevexpenses,
        ...expensesResponse?.expenses,
      ]);
    }
    setIsFetchingExpensesMore(false);
  }, [expensesResponse]);
  const totalIncomes = Number(incomesResponse?.total_sum) || 0; 
const totalExpenses = Number(expensesResponse?.total_sum) || 0; 


const total = (totalIncomes - totalExpenses).toFixed(2);

  return (
      <div className="max-w-5xl mx-auto bg-white p-4 rounded-lg shadow-md border border-gray-100">
       <p className="font-semibold flex justify-center text-2xl mb-2">
  Profit
  <AiOutlineDollarCircle
    color="gray"
    size={25}
    className="mt-1 ml-2"
  />
  <span
    className={
      total < 0 ? 'text-red-500' : 'text-green-500'
    }
  >
    &nbsp;${total}
  </span>
</p>

<div className="flex justify-center space-x-5 lg:text-[16px] text-[12px] mt-3">

 
  <div className="flex flex-col items-center">
    <div className="flex items-center bg-green-100 py-2 px-4 border-green-400 rounded-lg shadow-md">
      <div className="w-6 mr-1">
        <FaArrowUpLong color="green" size={16} />
      </div>
      <p className="font-semibold text-green-600">
        Total Income:
      </p>
      <p className="ml-2 text-green-600 font-semibold">
        ${totalIncomes}
      </p>
    </div>
    <div className="w-6 h-6 bg-green-500 flex items-center justify-center rounded-full mt-2 cursor-pointer hover:opacity-90 transition-opacity"
      onClick={() => setIsPaymentModalOpen(true)}
    >
      <IoAdd color="white" size={24} />
    </div>
  </div>


  <div className="flex flex-col items-center">
    <div className="flex items-center bg-red-100 py-2 px-4 border-red-400 rounded-lg shadow-md">
      <p className="font-semibold text-red-600">
        Total Expenses:
      </p>
      <p className="ml-2 text-red-600 font-semibold">
        ${totalExpenses}
      </p>
    </div>
    <div className="w-6 h-6 bg-red-500 flex items-center justify-center rounded-full mt-2 cursor-pointer hover:opacity-90 transition-opacity"
      onClick={() => handleRowClick(null)}
    >
      <IoAdd color="white" size={24} />
    </div>
  </div>

</div>



        <div className="flex justify-center mb-4 mt-3">
          <button
            className={`mx-2 px-4 py-2 ${
              activeTab === "income"
                ? "font-bold text-green-500 border-b-2 border-green-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("income")}
          >
            INCOME
          </button>
          <button
            className={`mx-2 px-4 py-2 ${
              activeTab === "expenses"
                ? "font-bold text-red-500 border-b-2 border-red-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("expenses")}
          >
            EXPENSES
          </button>
        </div>

        <div>
          {activeTab === "income" && (
            <main className="flex flex-col md:items-center mt-10 lg:mt-0 lg:flex-col flex-1 justify-center md:justify-around lg:justify-start text-[12px] lg:text-lg">
            
        <TimeSelectorComponent
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onDateChanged={() => {
            resetPaginationVars();
            setIncomes([]);
          }}
        />

              <div className="flex flex-col items-center gap-y-6 py-6">
                {isincomesLoading && incomePage === 1 ? (
                  <div>Loading...</div>
                ) : incomes?.length === 0 && incomePage === 1 ? (
                  <div className="text-center text-sm">
                    You have no incomes scheduled for this date
                  </div>
                ) : (
                  <>
                    {incomes?.map((income, index) => (
                      <div key={index} className="w-60">
                        <IncomeBox income={income} />
                      </div>
                    ))}
                    {console.log(incomesResponse)}
                    {incomesResponse.current_page < incomesResponse.pages && (
                      <button
                        className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
                        onClick={() => {
                          setIncomePage((page) => page + 1);
                          setIsFetchingIncomesMore(true);
                        }}
                      >
                        {isFetchingIncomesMore ? (
                          <CircularProgress size={23} sx={{ color: "white" }} />
                        ) : (
                          "Load More"
                        )}
                      </button>
                    )}
                  </>
                )}
                {isincomesLoading && incomePage > 1 && <CircularProgress />}
              </div>
            </main>
          )}
          {activeTab === "expenses" && (
            <main className="flex flex-col md:items-center mt-10 lg:mt-0 lg:flex-col flex-1 justify-center md:justify-around lg:justify-start">
              
        <TimeSelectorComponent
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onDateChanged={() => {
            resetPaginationVars();
            setIncomes([]);
          }}
        />

              <div className="flex flex-col items-center gap-y-4 py-6">
                {isexpensesLoading && expensesPage === 1 ? (
                  <div>Loading...</div>
                ) : expenses?.length === 0 && expensesPage === 1 ? (
                  <div className="text-center text-sm">
                    You have no expenses scheduled for this date
                  </div>
                ) : (
                  <>
                    {expenses?.map((expense, index) => (
                      <div
                        key={index}
                        className="w-60"
                        onClick={() => handleRowClick(expense)}
                      >
                        <ExpensesBox expense={expense} />
                      </div>
                    ))}
                    {expensesResponse.current_page < expensesResponse.pages && (
                      <button
                        className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
                        onClick={() => {
                          setExpensesPage((page) => page + 1);
                          setIsFetchingExpensesMore(true);
                        }}
                      >
                        {isFetchingExpensesMore ? (
                          <CircularProgress size={23} sx={{ color: "white" }} />
                        ) : (
                          "Load More"
                        )}
                      </button>
                    )}
                  </>
                )}
                {isexpensesLoading && expensesPage > 1 && <CircularProgress />}
              </div>
            </main>
          )}
        </div>
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          expense={selectedExpenses}
        >
          <div>
            <AddExpensesModal
              expense={selectedExpenses}
              onClose={handleCloseModal}
              resetPaginationVars={resetPaginationVars}
            />
          </div>
        </Modal>
        <Modal
        open={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div>
          <AddPaymentModal 
            onClose={() => setIsPaymentModalOpen(false)} 
            resetPaginationVars={resetPaginationVars}
          />
        </div>
      </Modal>
      </div>
  );
};

export default Profit;
