import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IoAdd } from "react-icons/io5";
import { CircularProgress, Modal } from '@mui/material';
import AddSessionModal from '../components/sessions/add_session_modal';
import dayjs from "dayjs";
import SessionBox from "../components/sessions/session_box";
import { useGetSessionsQuery } from "../redux/api/sessionApi";
import TimeSelectorComponent from "../components/calendar/time_selector_component";
import { PAGE_LIMIT } from "../utils/constants";
import { FaRegHourglass } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import { AiOutlineSchedule } from "react-icons/ai";
import CustomPieChart from "../components/charts/custom_pie_chart";
import WeeklySessionsChart from "../components/charts/weekly_session_chart";
import AddPaymentModal from "../components/sessions/add_payement";
import { setShowAddPaymentModal } from '../redux/storeSlice';
import Log from '../assets/log.png';

const SessionsPage = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs().startOf("day"));

  const [page, setPage] = useState(1);
  const [sessions, setSessions] = useState([]);

  const [selectedSession, setSelectedSession] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false); 

  const showAddPaymentModal = useSelector(state => state.store.showAddPaymentModal);

  const handleRowClick = (session) => {
    setSelectedSession(session);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSession(null);
  };

  const { data: sessionsResponse, isLoading: isSessionsLoading, error: sessionsError } = useGetSessionsQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    page, 
    limit: PAGE_LIMIT,
  });

  


  useEffect(() => {
    if (sessionsError) return;
    
    setIsAddModalOpen(showAddPaymentModal);

    if (page == 1) {
      setSessions(sessionsResponse?.sessions);
    } else {
      setSessions((oldSessions) => [...oldSessions, ...sessionsResponse?.sessions]);
    }
    setIsFetchingMore(false);  
  }, [sessionsResponse]);

  return (
    
      <section className='px-3 flex items-center justify-center'>
         <Modal
      open={isAddModalOpen}
      onClose={() => {
        setIsAddModalOpen(false);
        dispatch(setShowAddPaymentModal(false));
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div>
        <AddPaymentModal 
          onClose={() => { 
            setIsAddModalOpen(false); 
            dispatch(setShowAddPaymentModal(false)); 
          }} 
        />
      </div>
    </Modal>
        <div className='flex flex-col max-w-5xl px-3 w-full'>
        <div className="w-full flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-start space-x-2 mx-auto mt-4 lg:mt-0">
  <p className="flex items-center text-lg font-bold mb-4 text-center lg:text-left">
    Sessions Log
    <img src={Log} className="w-5 h-6 ml-2" />
  </p>

  <div className="mt-1 lg:mt-0 mx-auto lg:mx-0">
    <div
      className="w-6 h-6 bg-blue-500 flex items-center cursor-pointer hover:opacity-80 justify-center rounded-full"
      onClick={() => handleRowClick(null)}
    >
      <IoAdd color="white" size={20} />
    </div>
  </div>
</div>



<div className='h-3'></div>

<section className="mb-10"> 
  <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 lg:grid-cols-3 mt-3 justify-center lg:justify-between mx-auto">
    <div className="flex justify-center lg:justify-start">
      <div className="flex items-center bg-blue-300 py-1 border rounded-lg px-3">
        <div className="w-8">
          <AiOutlineSchedule size={22} />
        </div>
        <p>Total Sessions: </p>
        <p className="ml-2">
          {sessionsResponse?.total_sessions_all}
        </p>
      </div>
    </div>

    <div className="flex justify-center lg:justify-start">
      <div className="flex items-center bg-red-200 py-1 border rounded-lg px-3">
        <div className="w-8">
          <FaRegHourglass color="orange" size={18} />
        </div>
        <p>Total Pending Sessions: </p>
        <p className="ml-2 text-orange-500">
          {sessionsResponse?.total_pending_sessions}
        </p>
      </div>
    </div>

    <div className="flex justify-center lg:justify-start">
      <div className="flex items-center bg-green-300 py-1 border rounded-lg px-3">
        <div className="w-8">
          <FaRegCheckCircle color="green" size={22} />
        </div>
        <p>Total Paid Sessions: </p>
        <p className="ml-2 text-green-600">
          {sessionsResponse?.total_paid_sessions}
        </p>
      </div>
    </div>
  </div>
</section>


          <section className="flex flex-col lg:flex-row">
            <aside className="flex flex-col md:items-center md:flex-row lg:flex-col flex-1 justify-center md:justify-around lg:justify-start">
              <div className="flex flex-col w-full md:flex-row md:justify-around lg:flex-col lg:justify-start">
                  <div className="hidden md:block">
                <p className="my-5 font-semibold">
                  Nb. of Sessions in the last 7 days:
                </p>
                <WeeklySessionsChart data={sessionsResponse?.nb_of_sessions_lastweek} />
              </div>

                <div>
                  <p className="font-semibold my-5">
                    Session statuses in the last 7 days:
                  </p>
                  <div className="pointer-events-none lg:pointer-events-auto"> 
                  <CustomPieChart
                    data={[
                     {
                        value: sessionsResponse?.paid_pending_sessions_lastweek.Paid,
                        label: 'Paid',
                        displayValue: `${sessionsResponse?.paid_pending_sessions_lastweek.Paid} Paid`,
                        color: "#069e20",
                      },
                      {
                        value: sessionsResponse?.paid_pending_sessions_lastweek.Pending,
                        label: 'Pending',
                        displayValue: `${sessionsResponse?.paid_pending_sessions_lastweek.Pending} Pending`,
                        color: "#f57627",
                      },
                    ]}
                  />
                  </div>
                </div>
              </div>
            </aside>

            <main className="flex flex-col md:items-center mt-10 lg:mt-0 lg:flex-col flex-1 justify-center md:justify-around lg:justify-start text-sm lg:text-lg">
              <TimeSelectorComponent 
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                onDateChanged={() => {
                  setPage(1);
                  setSessions([]);
                }}
              />
              <div className="flex flex-col items-center gap-y-4 py-6">
                {isSessionsLoading && page === 1 ? (
                  <div>Loading...</div>
                ) : sessions?.length === 0 && page === 1 ? (
                  <div className="text-center text-sm">You have no sessions scheduled for this date</div>
                ) : (
                  <>
                    {sessions?.map((session, index) => (
                      <div key={index} onClick={() => handleRowClick(session)}>
                        <SessionBox session={session} />
                      </div>
                    ))}
                    {sessionsResponse.current_page < sessionsResponse.pages && (
                      <button 
                          className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
                          onClick={() => {
                            setPage((page) => page + 1);
                            setIsFetchingMore(true);
                          }}
                        >
                          {
                            isFetchingMore ?
                            <CircularProgress size={23} sx={{ color: 'white' }} />
                            : 
                            'Load More'
                          }
                        </button>
                    )}
                  </>
                )}
                {isSessionsLoading && page > 1 && <CircularProgress />}
              </div>
            </main>
          </section>

          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <div>
              <AddSessionModal session={selectedSession} onClose={handleCloseModal}/>
            </div>
          </Modal>
        </div>
      </section>
  );
};

export default SessionsPage;
