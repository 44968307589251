import { useGetExpensesQuery, useCreateExpensesMutation, useEditExpensesMutation } from '../../redux/api/expenses';
import { useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import { FaTrash } from 'react-icons/fa6';
import { Modal } from "@mui/material";
import { getMessageFromError } from '../../utils/utils';
import { MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteExpensesMutation } from '../../redux/api/expenses';
import DeleteExpensesModal from './delete_expenses'
import { setHideEdit } from '../../redux/storeSlice';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

const AddExpensesModal = ({ onClose, resetPaginationVars, expense }) => {
  const ExpenseId = useSelector(state => state.store.ExpenseId);
  const [selectedExpenses, setSelectedExpenses] = useState(null); 
  const [amount, setAmount] = useState(''); 
  const [details, setDetails] = useState('');  
  const [errMsg, setErrMsg] = useState('');
  const [date, setDate] = useState(dayjs());
  const { data: expensesResponse, isLoading: isExpensesLoading, refetch } = useGetExpensesQuery();
  const [expensess, setExpensess] = useState([]);
  const [addExpenses, { isLoading: isAddingLoading }] = useCreateExpensesMutation();
  const [editExpenses, { isLoading: isEditLoading }] = useEditExpensesMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteExpenses, { isLoading }] =
  useDeleteExpensesMutation();

  const handleDeleteExpenses = async () => {
    try {
      await deleteExpenses(expense.expense_id).unwrap();
      onClose();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (expense) {
      setSelectedExpenses(expense.expense_id);
      setAmount(expense.amount || ''); 
      setDetails(expense.details || ''); 
      setDate(expense?.date_added ? dayjs(expense.date_added) : dayjs());

    } else {
      setSelectedExpenses('');
      setAmount('');
      setDetails('');
    }
  }, [expense]);

  const handleAddExpenses = async () => {
    try {
      if (expense) {
        await editExpenses({
          expense_id: expense.expense_id,
          amount,
          details,
          date_added:date
        }).unwrap();
      } else {
        await addExpenses({
          expense_id: selectedExpenses,
          amount,
          details,
          date_added:date
        }).unwrap();
      }
      refetch();  // Refetch expenses to update the list
      resetPaginationVars();
      onClose();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };



  return (
    <div
      className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="relative mx-4 px-7 py-6 w-[500px] overflow-y-auto bg-white rounded-lg overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between">
          <p className="font-bold text-lg">
            {expense ? "Edit Expenses" : "Create Expenses"}
          </p>
          <IoIosClose
            size={30}
            className="text-gray-500 cursor-pointer"
            onClick={() => onClose()}
          />
        </div>
        <div className="h-4"></div>
        <article className="mt-3">
          <label className="font-semibold">Amount</label>
          <div className="h-2"></div>
          <TextField
            value={amount}  // Ensure value is controlled
            onChange={(e) => setAmount(e.target.value)}
            variant="outlined"
            type="number"
            inputProps={{ min: 1 }}
            fullWidth
          />
        </article>
        <article className="mt-3">
          <label htmlFor="" className="font-semibold">Details</label>
          <div className="h-2"></div>
          <TextField
            value={details}  // Ensure value is controlled
            onChange={(e) => setDetails(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </article>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className='flex gap-x-3'>
                                    <article>
                    <label className='font-semibold'>Date</label>
                    <div className='h-2'></div>
                    <div>
                        <DatePicker
                            label=""
                            value={date}
                            onChange={(newValue) => setDate(newValue)}
                            slotProps={{ textField: { fullWidth: true } }}
                            maxDate={dayjs()} 
                        />
                    </div>
                </article>
                        
                    </div>
                </LocalizationProvider>
        <div className="flex justify-between items-center mt-6">
          <FormSubmitBtn
            text={expense ? "Edit" : "Add"}  
            isLoading={isAddingLoading || isEditLoading} // Handle both add/edit loading
            onClick={() => handleAddExpenses()}
          />   
           {expense && (
         <FormSubmitBtn
         text="Delete"
         isLoading={isLoading}
         onClick={() => {handleDeleteExpenses(); onClose()}} 
         className="bg-red-500 hover:bg-red-600 text-white rounded-lg transition-all duration-200 ease-in-out"
     />
        )}
        </div>
        {errMsg && <p className="text-red-500 mt-3 text-center">{errMsg}</p>}
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div>
          <DeleteExpensesModal 
            onClose={() => setIsModalOpen(false)} 
            resetPaginationVars={resetPaginationVars}
            expense_id = {ExpenseId}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddExpensesModal;
