import React, { useState, useEffect, useRef } from 'react';
import { IoIosClose } from 'react-icons/io';
import dayjs from 'dayjs';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import { useParams } from 'react-router-dom';
import { useGetSessionsByUserQuery } from '../../redux/api/sessionApi';
import { useGetPaymentsByUserQuery } from '../../redux/api/paymentSlice';
import { FaPrint, FaDownload, FaShareAlt, FaWhatsapp } from 'react-icons/fa';
import { profileService } from '../../services/profile_services';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import html2pdf from 'html2pdf.js';

const Statement = ({ onClose, trainee }) => {
  const { id } = useParams();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const [sessions, setSessions] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');
  const { data: sessionsResponse, isLoading: isSessionsLoading } = useGetSessionsByUserQuery({
    traineeId: id,
    page: 1,
    limit: 1000,
  });
  const { data: paymentsResponse, isLoading: isPaymentsLoading } = useGetPaymentsByUserQuery({
    traineeId: id,
    page: 1,
    limit: 1000,
  });
  const [profile, setProfile] = useState({ firstname: '', lastname: '', sports: [] });
  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profileData, sportsData] = await Promise.all([
          profileService.getProfile(),
          profileService.getSports(),
        ]);

        // Extract and set the sports data from profileData
        const profileSports = profileData.sports || [];
        setProfile({
          firstname: profileData.firstname,
          lastname: profileData.lastname,
          sports: profileSports,
        });

        // Update the sports state if needed, or use profileSports directly
        setSports(profileSports);
      } catch (err) {
        setError('Failed to fetch user details');
        console.error('Error fetching user details: ', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const [errMsg, setErrMsg] = useState('');
  const invoiceRef = useRef(null);

  useEffect(() => {
    setSessions(sessionsResponse?.sessions || []);
  }, [sessionsResponse]);
  useEffect(() => {
    setPayments(paymentsResponse?.transactions || []);
  }, [paymentsResponse]);
  
  useEffect(() => {
    // Set the default end date to today when the component mounts
    const today = new Date().toISOString().split("T")[0];
    setEndDate(today);
  }, []);
  const filteredSessions = sessions.filter(session => {
    const sessionDate = dayjs(session.date);
    return (
      (!startDate || sessionDate.isSame(startDate, 'day') || sessionDate.isAfter(startDate)) &&
      (!endDate || sessionDate.isSame(endDate, 'day') || sessionDate.isBefore(endDate))
    );
  });
  
  const filteredPayments = payments.filter(payment => {
    const paymentDate = dayjs(payment.date_added);
    return (
      (!startDate || paymentDate.isSame(startDate, 'day') || paymentDate.isAfter(startDate)) &&
      (!endDate || paymentDate.isSame(endDate, 'day') || paymentDate.isBefore(endDate))
    );
  });
  
  // Combine sessions and payments into a single array
  const combinedData = [
    ...filteredSessions.map(session => ({
      date: session.date,
      transactionType: session.price > 0 ? "Session" : "Payment",
      details: session.note,
      amount: session.price,
    })),
    ...filteredPayments.map(payment => ({
      date: payment.date_added,
      transactionType: payment.amount > 0 ? "Payment" : "Session",
      details: payment.note,
      amount: payment.amount,
    }))
  ];
  
  // Sort by date
  combinedData.sort((a, b) => new Date(a.date) - new Date(b.date));
  const totalPaid = combinedData.reduce((acc, entry) => {
    const amountValue = parseFloat(entry.amount);
    // Only include payments in totalPaid
    return acc + (entry.transactionType === "Payment" ? amountValue : 0);
  }, 0);
  
  const totalSessionsValue = combinedData.reduce((acc, entry) => {
    const amountValue = parseFloat(entry.amount);
    // Only include sessions in totalSessionsValue
    return acc + (entry.transactionType === "Session" ? (-amountValue) : 0);
  }, 0);
  
  const totalBalance = totalPaid + totalSessionsValue; // Assuming balance is total paid minus session values
  
let downloadCount = 0;

const Download = () => {
  const printContents = invoiceRef.current.innerHTML;

  // Combine invoice content and styles
  const invoiceHTML = `
    <html>
  
      <body>
        <div class="invoice">
          ${printContents}
        </div>
      </body>
    </html>
  `;

  // Create a unique timestamp for each download
  const timestamp = new Date().toISOString().replace(/[:.]/g, '-');

  // Define options for PDF with unique filename
  const opt = {
    margin: 1,
    filename: `statement_of_account_${timestamp}.pdf`, // Filename includes timestamp for uniqueness
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };

  // Use html2pdf to convert the invoice HTML to PDF
  html2pdf().from(invoiceHTML).set(opt).save();
};


const handleShareOrDownloadPDF = () => {
    const printContents = invoiceRef.current.innerHTML;
  
    // Combine invoice content and styles for sharing
    const invoiceHTML = `
      <html>
        <body>
          <div class="invoice">
            ${printContents}
          </div>
        </body>
      </html>
    `;
  
    // Create a temporary element for the invoice content
    const invoiceElement = document.createElement('div');
    invoiceElement.innerHTML = invoiceHTML;
  
    // Generate PDF from invoice HTML
    generatePDF(invoiceElement)
      .then((pdfFile) => {
        console.log('Generated PDF:', pdfFile);
  
        // Attempt to share the file using the Web Share API
        if (navigator.canShare && navigator.canShare({ files: [pdfFile] })) {
          navigator
            .share({
              title: 'Invoice',
              text: 'Here is your invoice as a PDF:',
              files: [pdfFile],
            })
            .then(() => console.log('Invoice shared successfully'))
            .catch((error) => console.error('Error sharing invoice:', error));
        } else {
          // Fallback to download if Web Share API is not supported
          downloadPDF(pdfFile);
        }
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });
  };
  

const generatePDF = (element) => {
  downloadCount += 1; // Increment the counter on each download

  const options = {
    margin: 1,
    filename: `statement_of_account${downloadCount}.pdf`, // Generates 'invoice1.pdf', 'invoice2.pdf', etc.
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };

  html2pdf().from(element).set(options).save();

  
    return new Promise((resolve, reject) => {
      html2pdf()
        .from(element)
        .set(options)
        .toPdf()
        .output('blob')
        .then((pdfBlob) => {
          const pdfFile = new File([pdfBlob], 'invoice.pdf', { type: 'application/pdf' });
          resolve(pdfFile); // Return the PDF file
        })
        .catch((error) => reject(error));
    });
  };
  
  // On the /download page, handle automatic PDF download based on URL parameters
  // You need to create a new page that will read the "fileUrl" parameter and download it automatically
  

  const downloadPDF = (pdfFile) => {
    downloadCount += 1; // Increment the counter on each download
  
    const pdfBlobUrl = URL.createObjectURL(pdfFile);
    const link = document.createElement('a');
    link.href = pdfBlobUrl;
    link.download = `invoice${downloadCount}.pdf`; // Generates 'invoice1.pdf', 'invoice2.pdf', etc.
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
    alert('PDF downloaded. You can now share it manually.');
  };



const renderSessionDetails = () => {
  // Parse the start and end dates
  const parsedStartDate = dayjs(startDate);
  const parsedEndDate = dayjs(endDate).endOf('day'); // Include the entire end date

  // Debugging output
  console.log("Parsed Start Date:", parsedStartDate.format());
  console.log("Parsed End Date:", parsedEndDate.format());

  return (
    <div className="overflow-x-auto">
    <table className="min-w-full text-sm md:text-base table-auto border-collapse">
      <thead>
        <tr>
          <th className="px-4 py-2 text-left font-semibold">Date</th>
          <th className="px-4 py-2 text-left font-semibold">Transaction Type</th>
          <th className="px-4 py-2 text-left font-semibold">Details</th>
          <th className="px-4 py-2 text-left font-semibold">Amount</th>
        </tr>
      </thead>
      <tbody>
        {combinedData.map((entry, index) => {
          const entryDate = dayjs(entry.date); // Parse entry date
          const amountValue = parseFloat(entry.amount); // Convert to a number
  
          if (isNaN(amountValue)) {
            console.error(`Invalid amount for entry at index ${index}:`, entry.amount);
            return null; // Skip this entry or handle it as needed
          }
  
          const isInDateRange =
            entryDate.isSame(parsedStartDate, 'day') ||
            entryDate.isSame(parsedEndDate, 'day') ||
            entryDate.isBetween(parsedStartDate, parsedEndDate, null, '[]');
  
          if (isInDateRange) {
            const displayAmount = entry.transactionType === "Session"
              ? (-amountValue).toFixed(2)
              : amountValue.toFixed(2);
  
            const amountColor = displayAmount < 0 ? "red" : "green";
  
            return (
              <tr key={index} className="border-b">
                <td className="px-4 py-2">{entryDate.format("DD/MM/YYYY")}</td>
                <td className="px-4 py-2">{entry.transactionType}</td>
                <td className="px-4 py-2">{entry.details}</td>
                <td className="px-4 py-2" style={{ color: amountColor }}>
                  {displayAmount}
                </td>
              </tr>
            );
          }
  
          return null; // Exclude entries outside the date range
        })}
      </tbody>
    </table>
  </div>
  

  );
};



  return (
    <div
      className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="relative mx-4 px-8 py-6 w-[600px] max-w-full h-auto max-h-[95vh] overflow-y-auto bg-white rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6 pb-2 border-b border-gray-300">
          <h2 className="font-bold text-2xl text-gray-900">Statment of Account</h2>
          <IoIosClose
            size={35}
            className="text-gray-500 cursor-pointer hover:text-red-500 transition-colors duration-200"
            onClick={onClose}
          />
        </div>
        <p className="font-semibold text-lg mb-3">Please Select the Start and End Date </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 overflow-y-auto max-h-[300px] mb-4">
      <div className="flex flex-col items-start text-lg">
        <label htmlFor="start-date" className="mb-2">Start Date:</label>
        <input
          type="date"
          id="start-date"
          max={new Date().toISOString().split("T")[0]}
          className="border p-2 rounded w-full"
          value={startDate}  // State variable for startDate
          onChange={(e) => setStartDate(e.target.value)}  // Update the start date
        />
      </div>

      <div className="flex flex-col items-start text-lg">
        <label htmlFor="end-date" className="mb-2">End Date:</label>
        <input
          type="date"
          id="end-date"
          className="border p-2 rounded w-full"
          value={endDate}  // State variable for endDate
          onChange={(e) => setEndDate(e.target.value)}  // Update the end date
          max={new Date().toISOString().split("T")[0]} // Prevent future dates
        />
      </div>
    </div>

    <div
  className="invoice mt-6"
  ref={invoiceRef}
  style={{
    maxWidth: '900px',
    margin: 'auto',
    border: 'none',
    padding: '30px',
    borderRadius: '15px',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.15)',
    background: '#ffffff',
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '15px',
  }}
>
  <div className="invoice-header mb-5">
    <h3 className="invoice-heading text-2xl font-bold text-green-600 mb-3">
      Statement of Account
    </h3>
    <hr className="invoice-divider border-0 border-t-2 border-gray-300 my-4" />

    <div className="invoice-body flex flex-col md:flex-row justify-between">
      {/* Left Section */}
      <div className="left-section w-full md:w-1/2 mb-4 md:mb-0">
        <p className="invoice-info mb-3 text-lg">
          <b>Invoice Date:</b> {new Date().toLocaleDateString('en-GB')}
        </p>
        <p className="invoice-info mb-3 text-lg">
          <b>Invoice To:</b> {trainee.firstname} {trainee.lastname}
        </p>
      </div>

      {/* Right Section */}
      <div className="right-section w-full md:w-1/2">
        <p className="invoice-info mb-3 text-lg">
          <b>Trainer:</b> {profile.firstname} {profile.lastname}
        </p>
        <p className="invoice-info mb-3 text-lg">
          <b>Date Range:</b> {startDate && endDate ? `${new Date(startDate).toLocaleDateString('en-GB')} to ${new Date(endDate).toLocaleDateString('en-GB')}` : 'N/A'}
        </p>
      </div>
    </div>

    {/* Render Session Details */}
    {renderSessionDetails()}

    {/* Financial Summary */}
    <div className="text-right text-base">
      <p>
        <span className="font-bold">PAID TO DATE: </span>
        <span className="text-green-600">${totalPaid.toFixed(2)}</span>
      </p>
      <p>
        <span className="font-bold">SESSIONS VALUE: </span>
        <span className="text-red-600">${totalSessionsValue.toFixed(2)}</span>
      </p>
      <p>
        <span className="font-bold">BALANCE: </span>
        <span className={totalBalance < 0 ? 'text-red-600' : 'text-green-600'}>
          ${totalBalance.toFixed(2)}
        </span>
      </p>
    </div>

    {/* Footer Section */}
    <div className="text-lg font-sans">
      <p>
        <span className="font-bold">POWERED BY:</span>
        <br />
        <span className="font-bold mt-2 block text-lg">CLICK COACH</span>
      </p>
    </div>
  </div>
</div>




        {/* Print Button */}

<div className="flex justify-end items-center">
  
  <button
    className="text-red-500 hover:text-red-700 ml-3"
    onClick={() => {
      invoiceRef.current.classList.remove('hidden');

      setTimeout(() => {
        Download();
        onClose();
        invoiceRef.current.classList.add('hidden');
      }, 100);
    }}
  >
    <FaDownload size={24} /> {/* Icon for Download */}
  </button>
  
  <button
    className="text-green-500 hover:text-green-700 ml-3"
    onClick={() => {
      handleShareOrDownloadPDF();
      onClose();
    }}
  >
    <FaShareAlt size={24} /> {/* Icon for Share */}
  </button>
  
</div>


        {/* Error Message */}
        {errMsg && <p className="text-red-500 mt-4 text-center font-semibold">{errMsg}</p>}
      </div>
    </div>
  );
};

export default Statement;
