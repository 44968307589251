import React, { useEffect, useState } from 'react'
import FloatingInput from '../components/inputs/floating_input/floating_input'
import CustomRadioButton from '../components/inputs/radio/radio_btn';
import 'react-phone-number-input/style.css';
import { Autocomplete,TextField,Button} from '@mui/material';
import PhoneInput from 'react-phone-number-input'
import PageButtonNo from '../components/buttons/page_no_button/page_no_button';
import NormalInput from '../components/inputs/normal_input/normal_input';
import WideButton from '../components/buttons/wide_button/wide_button';
import ProfilePic from '../components/profile/profile_pic';
import UserLogo from '../components/profile/user_logo';
import { IoAdd } from 'react-icons/io5';
import Locations from '../components/profile/locations';
import { profileService } from '../services/profile_services';
import { profile1ValidationSchema } from '../utils/form-validations/profile_validation_schema';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {setUserData} from '../redux/userSlice';
import defaultPic from '../assets/profile_pic.jpg';
import {useGetSportByNameQuery} from '../redux/api/sessionApi'
import {useAddSportByNameMutation} from '../redux/api/sessionApi'
import { getMessageFromError } from '../utils/utils';

const ProfilePage = () => {
    const user = useSelector((state => state.user.data));
    const [sports, setSports] = useState([]); // Holds the fetched sport options
  const [selectedSports, setSelectedSports] = useState([]); // State for selected sports
  const [searchInput, setSearchInput] = useState(''); // Holds the input value for querying the API
  const [addSportByName, { isLoading }] = useAddSportByNameMutation();
  const { data: sportResponse } = useGetSportByNameQuery({ SportName: searchInput });
  const [errMsg, setErrMsg] = useState('');
 const [sportName,setSportName]=useState('');
 const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (sportResponse) {
      setSports(sportResponse); // Update options when the API response changes
    }
  }, [sportResponse]);

  const handleAddSportName = async () => {
    try {
        await addSportByName({sport: sportName }).unwrap();
        setShowForm(false)
        setSportName('');
    } catch (error) {
        setErrMsg(getMessageFromError(error));
      }
};



const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        handleAddSportName(); // Call the function when Enter is pressed
    }
};
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profName, setProfName] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [yearsOfExp, setYearsOfExp] = useState('');
    const [locations, setLocations] = useState([]);
    const [logoSrc, setLogoSrc] = useState(''); // base 64
    const [logo, setLogo] = useState('');


    const [image, setImage] = useState(null);
    // hold base 64
    const [imageSrc, setImageSrc] = useState('')

    const [page, setPage] = useState(1);
    const [err, setErr] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validatePage1 = async (event) => {
        event.preventDefault();
        const data = {
            firstName: event.target[0].value,
            lastName: event.target[1].value,
            phoneNumber: event.target[2].value,
        }
        const isValid = profile1ValidationSchema.isValid(data);
    }

    console.log(selectedSports);

    const submit = async () =>  {
       setErr('');

       const response =  await profileService.editProfile({
            firstname: firstName,
            lastname: lastName,
            profname: profName,
            gender: gender,
            telephone: phone,
            locations: locations,
            selectedSports:selectedSports,
            yearsOfExperience: yearsOfExp,
            image: image, // not imageSrc, because want to send a file not base64
            logo: logo,
        });
        if (response.success) {
            dispatch(setUserData(response.data));
            navigate('/');
        } else {
            setErr(response.error);
        }
    }


    // Update state when user data changes
    useEffect(() => {
        if (user) {
            console.log(user);
            
            setFirstName(user.firstname || '');
            setLastName(user.lastname || '');
            setProfName(user.profname || '');
            setPhone(user.telephone || '');
            setGender(user.gender || '');
            setLocations(user.locations || ['']);
            setYearsOfExp(user.years_of_experience || '');
            setSelectedSports(user.sports ? user.sports.map(sport => ({
                sport_id: sport.sport_id,
                name: sport.name
            })) : []);            
            
            setLogoSrc(user.logo || '');
            setImageSrc(user.image||defaultPic);           
        }
    }, [user]);

    return (
        <div className='flex flex-col justify-center items-center'>
            <div className='flex flex-col items-center  w-full max-w-[800px] bg-lightGray h-full md:h-auto md:shadow-md'>
                <header className='bg-main rounded-b-2xl w-full flex flex-col justify-center items-center'>
                    <div className='h-2'></div>
                    <p className='text-white font-semibold text-2xl '>Profile</p>
                    <div className='h-2'></div>
                    <p className='text-white text-sm px-3 text-center' >Your information will help us simplify your coaching journey</p>
                    <div className='h-4'></div>
                    <ProfilePic 
                        imageSrc={imageSrc} 
                        setImageSrc={setImageSrc} 
                        setImage={setImage}
                    />
                    <div className='h-4'></div>
                </header>
                <section className='w-4/5'>
                        <div className='h-4' ></div>
                    <div className='flex justify-center'>
                       <PageButtonNo isSelected={page == 1} label="1" onTap={() => {
                           setPage(1);
                       }} />
                        <div className='w-2' ></div>
                       <PageButtonNo isSelected={page == 2} label="2" onTap={() => { 
                           setPage(2);
                        }} />
                    </div>

                    {
                        page == 1 ?

                        <form onSubmit={validatePage1}>
                            <div className='h-4' ></div>
                            <p className='font-semibold text-md'>Personal information</p>
                            <div className='h-4' ></div>
                            <FloatingInput value={firstName} onChange={(e) => setFirstName(e.target.value)} label="First Name" />
                            <div className='h-4' ></div>
                            <FloatingInput value={lastName} onChange={(e) => setLastName(e.target.value)} label="Last Name" />
                            <div className='h-4'></div>
                            <FloatingInput value={profName} onChange={(e) => setProfName(e.target.value)} label="Professional Name" />
                            <div className='h-4'></div>
                        
                            <PhoneInput
                                className="phone-input-custom"
                                placeholder="Enter phone number"
                                defaultCountry="LB"
                                value={phone}
                                onChange={setPhone} 
                            />
                            <div className='h-5' ></div>

                            <div className='h-4' ></div>
                            <p className='font-semibold text-md'>Gender</p>
                            <div className='h-3'></div>
                        
                            <div className='flex justify-between'>
                                <CustomRadioButton
                                    value='male'
                                    label="Male"
                                    checked={gender == 'male'}
                                    onChange={(e) => setGender(e.target.value)}
                                />
                                <CustomRadioButton
                                    value='female'
                                    label="Female"
                                    checked={gender == 'female'}
                                    onChange={(e) => setGender(e.target.value)}
                                />
                              
                            </div>
                            <div className='h-5'></div>
                            <WideButton label="Continue" onClick={() => setPage(2)} />
                            <div className='h-5'></div>
                        </form> 
                        : 
                        <>
                            <div className="w-full"> {/* Responsive padding and width */}

      <p className="font-semibold text-md text-center md:text-left">
        Sports you teach
      </p>
      <div className="h-4"></div>

      {/* Responsive Autocomplete component */}
      <Autocomplete
    multiple
    id="sports-autocomplete"
    value={selectedSports} // Use the state as the value for selected items
    onChange={(event, newValue) => setSelectedSports(newValue)} // Handle changes in selected items
    options={sports || []} // Use the fetched sports as options (fallback to empty array)
    getOptionLabel={(option) => option.name || ''} // Display the sport name in the dropdown
    filterSelectedOptions // Hide already-selected options from the dropdown
    onInputChange={(event, newInputValue) => setSearchInput(newInputValue)} // Update search input for the API call
    isOptionEqualToValue={(option, value) => option.sport_id === value.sport_id} // Custom equality check
    sx={{ 
        width: '100%',
        '& .MuiInputBase-root': { padding: '6px 8px' }, // Custom input padding for mobile
    }}
    renderInput={(params) => (
        <TextField
            {...params}
            label="Select Sports" 
            placeholder="Start typing..."
            size="small"
        />
    )}
/>

<div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }} className='mt-3'>

  <div>
    Add a Sport
  </div>

  <div
                className="w-6 h-6 bg-main flex items-center cursor-pointer hover:opacity-80 justify-center rounded-full"
                onClick={() => setShowForm(true)}
              >
                <IoAdd color="white" size={20} />
              </div>
</div>

      {showForm && (
        <div
          style={{
            marginTop: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <TextField
            value={sportName}
            onChange={(e) => setSportName(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder='Add a Sport...'
            variant="outlined"
            type="text"
            style={{ width: '100%', maxWidth: '300px' }} // Adjust width responsively
          />
          <button
            onClick={handleAddSportName}
            style={{
              backgroundColor: '#1e3a8a',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '6px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease-in-out',
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#1e40af'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#1e3a8a'}
          >
            Submit
          </button>
          {errMsg && <p className="text-red-500 mt-3 text-center">{errMsg}</p>}
        </div>
      )}
    </div>
</div>
    
    
                            <div>
                                <div className='h-4' ></div>
                                <p className='font-semibold text-md'>Years of Experience</p>
                                <div className='h-3'></div>
                                <NormalInput 
                                    value={yearsOfExp}    
                                    onChange={(e) => setYearsOfExp(e.target.value)}
                                    placeholder="Enter your years of experience. (Eg. 3)" 
                                    digitsOnly={true} 
                                />
                            </div>
                            <section>
                                <div className='h-6' ></div>
                                <Locations locations={locations} setLocations={setLocations} />
                                <div className='h-8'></div>
                                <UserLogo logoSrc={logoSrc} setLogoSrc={setLogoSrc} setLogo={setLogo} />
                                <div className='h-8'></div>
                                <WideButton label="Submit" onClick={async () => await submit()}/>
                                {err && <p className='text-red-500 my-3 text-center'>{err}</p>}
                            </section>
                            <div className='h-8'></div>
                        </>
                    }
                </section>
            </div>
        </div>
    )
}

export default ProfilePage