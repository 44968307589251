import React, { useRef, useState } from "react";
import { IoIosClose } from "react-icons/io";
import PhoneInput from "react-phone-number-input";
import ErrorPopup from "../common/error_popup";
import {
  useAddTraineeMutation,
  useDeleteTraineeMutation,
  useEditTraineeMutation,
} from "../../redux/api/traineeApi";
import { CircularProgress, TextField } from "@mui/material";
import { getMessageFromError } from "../../utils/utils";
import SubTraineesBox from "./subtrainees_box";
import FormSubmitBtn from "../buttons/form_submit_btn/form_submit_btn";

const AddTraineeModal = ({ trainee, onClose }) => {
  const id = trainee?.customer_id;
  const [firstName, setFirstName] = useState(trainee?.firstname ?? "");
  const [lastName, setLastName] = useState(trainee?.lastname ?? "");
  const [phone, setPhone] = useState(trainee?.telephone ?? "");
  const [gender, setGender] = useState(trainee?.gender ?? "");
  const [subtrainees, setSubtrainees] = useState(
    trainee?.subtrainees?.map((subtrainee) => subtrainee.name) ?? [""]
  );
  const [isPickerVisible, setIsPickerVisible] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  
  const colorRef = useRef(null);
  const [color, setColor] = useState(''); 
  const predefinedColors = ['#FF6B6B', '#98E37E', '#FFF07C', '#B09EFF', '#FF99E2', '#98F0FF', '#D9D9D9', '#767676'];
  const togglePicker = () => {
    setIsPickerVisible(!isPickerVisible); 
};
  const handleCustomColor = () => {
      colorRef.current.click(); 
  };

  const handleSetColor = (newColor) => {
      setColor(newColor);
  };
  const [addTrainee, { isLoading: isAddLoading }] = useAddTraineeMutation();
  const [editTrainee, { isLoading: isEditLoading }] = useEditTraineeMutation();
  const [deleteTrainee, { isLoading: isDeleteLoading }] =
    useDeleteTraineeMutation();

    const selectContact = async () => {
      try {
       
        const contactPickerSupported = 'contacts' in navigator && 'select' in navigator.contacts;
    
        if (contactPickerSupported) {
       
          const props = ['tel'];
          const options = { multiple: false };
    
       
          const contact = await navigator.contacts.select(props, options);
    
         
          if (contact && contact.length > 0 && contact[0].tel && contact[0].tel.length > 0) {
            setPhone(contact[0].tel[0]); 
          } else {
            alert('No phone number found for the selected contact.');
          }
        } else {
         
          const userAgent = navigator.userAgent || navigator.vendor || window.opera;
          
        
          const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    
          if (isIOS) {
            alert('Contact Picker API is not supported on iOS browsers. Please enter your phone number manually.');
          } else {
          
            const isAndroid = /android/i.test(userAgent);
            
            if (isAndroid) {
              alert('Contact Picker is not supported on this Android device. Please enter your phone number manually.');
            } else {
              alert('Contact Picker is not supported on this device or browser. Please enter your phone number manually.');
            }
          }
    
         
        }
      } catch (error) {
        console.error("Error selecting contact: ", error);
        alert('An error occurred while selecting a contact. Please try again.');
      }
    };
    


  const handleCreateTrainee = async () => {
    try {
      await addTrainee({
        firstname: firstName,
        lastname: lastName,
        telephone: phone,
        gender: gender,
        trainees: subtrainees,
        // "dob": dob,
        color: color,
      }).unwrap();
      onClose();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  const handleDeleteTrainee = async () => {
    try {
      await deleteTrainee(id).unwrap();
      onClose();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  const handleEditTrainee = async () => {
    try {
      await editTrainee({
        id: id,
        firstname: firstName,
        lastname: lastName,
        telephone: phone,
        gender: gender,
        trainees: subtrainees,
        // "dob": dob,
        color: color,
      }).unwrap();
      onClose();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  return (
    <div
    className="fixed inset-0 flex py-10 px-5 sm:px-0 items-center justify-center bg-black bg-opacity-50"
    onClick={() => onClose()}
>
    <div
        className="relative w-[500px] max-h-[90vh] bg-white rounded-lg overflow-hidden"
        onClick={(e) => e.stopPropagation()}
    >
        <div className="flex justify-between px-6 pt-6">
            <p className="font-bold text-lg">
                Create Trainee Account
                <p style={{fontSize:'13px',fontStyle:'italic',fontWeight:'normal'}}>
                    Add multiple trainees to log group sessions for families and relatives
                </p>
            </p>
            <IoIosClose
                size={30}
                className="text-gray-500 cursor-pointer"
                onClick={() => onClose()}
            />
        </div>
        <div className="p-6 overflow-y-auto max-h-[70vh]"> {/* Adjusted max-height for scrolling */}
            <div className="space-y-4">
                <div>
                <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 mb-2">
    <label className="font-semibold">Account Responsible Name</label>
    <p className="text-sm italic font-normal mt-1 sm:mt-0"> {/* Added mt-1 for small screens */}
        The Person Handling Payments
    </p>
</div>

                    <div className="h-2"></div>
                    <TextField
                        value={firstName}
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <div>
                    <TextField
                        value={lastName}
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <div>
  <label htmlFor="" className="font-semibold text-sm mr-2">Phone Number</label>
  

  <button
    onClick={selectContact}
    className="mb-2 p-1 rounded bg-green-500 text-white cursor-pointer border-none sm:hidden"
  >
    Select a Contact
  </button>
  
  <PhoneInput
    className="phone-input-custom mt-2"
    placeholder="phone number"
    defaultCountry="LB"
    value={phone}
    style={{
      border: 0,
      borderRadius: "3px",
      paddingLeft: "10px",
      paddingTop: "3px",
      border: "1.2px solid #D1D5DB",
    }}
    onChange={setPhone}
  />
</div>

          <button 
                onClick={togglePicker} 
                className="text-lg font-bold text-white bg-gray-900 rounded-lg px-2 py-1 focus:outline-none"
            >
                {isPickerVisible ? 'Hide color picker' : 'Select color'}
            </button>  
            {isPickerVisible && (         
<div className="p-4 bg-gray-900 rounded-xl w-64 text-white">
            <h3 className="mb-4 text-lg">Select color</h3>
            <div className="grid grid-cols-4 gap-2">
                {predefinedColors.map((col, index) => (
                    <div
                        key={index}
                        onClick={() => handleSetColor(col)}
                        className={`w-12 h-12 rounded cursor-pointer border-4 ${color === col ? 'border-white' : 'border-transparent'}`}
                        style={{ backgroundColor: col }}
                    ></div>
                ))}
            </div>
            <div className="mt-4 flex items-center justify-between">
                <button onClick={handleCustomColor} className="text-blue-400">Custom</button>
                <div className="flex items-center">
                    <span>Chosen color</span>
                    <div className="ml-4 w-12 h-12 rounded" style={{ backgroundColor: color }}></div>
                </div>
            </div>
  
            <input
                ref={colorRef}
                type="color"
                value={color}
                onChange={(e) => setColor(e.target.value)}
                className="w-0 h-0 p-0 m-0 cursor-pointer"
            />
            <ErrorPopup
                        message={errMsg}
                        isVisible={errMsg?.length > 0}
                        onClose={() => setErrMsg("")}
                    />
        </div>
         
              )}
                <SubTraineesBox
                    subtrainees={subtrainees}
                    setSubtrainees={setSubtrainees}
                />
                <div className="flex justify-end my-3 space-x-2">
                    {trainee == null ? (
                        <FormSubmitBtn
                            onClick={handleCreateTrainee}
                            text="Create"
                            isLoading={isAddLoading}
                        />
                    ) : (
                        <>
                            <button
                                onClick={handleDeleteTrainee}
                                className="bg-red-600 text-white px-5 py-1 rounded-md hover:opacity-80"
                            >
                                {!isDeleteLoading ? (
                                    "Delete"
                                ) : (
                                    <CircularProgress size={20} color="inherit" />
                                )}
                            </button>

                            <FormSubmitBtn
                                onClick={handleEditTrainee}
                                text="Edit"
                                isLoading={isEditLoading}
                            />
                        </>
                    )}
                </div>
                <div className="h-7"></div>
            </div>
        </div>
    </div>
</div>

  );
};

export default AddTraineeModal;
