import { MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  CircularProgress, Modal } from '@mui/material';
import { IoIosAdd, IoIosClose } from 'react-icons/io';
import { useGetTraineesQuery } from '../../redux/api/traineeApi';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import AddTraineeModal from '../../components/trainees/add_trainee';
import { useCreatePaymentMutation } from '../../redux/api/paymentSlice';
import { getMessageFromError } from '../../utils/utils';
import { setShowAdd } from '../../redux/storeSlice';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

const AddPaymentModal = ({ onClose, resetPaginationVars }) => {
  let trainee = useSelector((state) => state.store.trainee);
  const dispatch = useDispatch();
  const [selectedTrainee, setSelectedTrainee] = useState(trainee); 
  const [amount, setAmount] = useState(''); 
  const [note, setNote] = useState('');  
  const [errMsg, setErrMsg] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: traineesResponse, isLoading: isTraineesLoading } = useGetTraineesQuery();
  const [trainees, setTrainees] = useState([]);
  const [selectedTraineeAccount, setSelectedTraineeAccount] = useState(null);
  const [addPayment, { isLoading }] = useCreatePaymentMutation();
  const [date, setDate] = useState(dayjs());
  

  useEffect(() => {
    setTrainees(traineesResponse?.trainees);
  }, [traineesResponse]);

  const handleAddPayment = async () => {
    try {
      await addPayment({
        trainee_id: selectedTrainee?.customer_id, 
        amount: amount,
        note: note,
        date:date,
        date_added:date
      }).unwrap();
      resetPaginationVars();
      onClose();
      dispatch(setShowAdd(false));

    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };
  const handleRowClick = (trainee) => {
    setSelectedTraineeAccount(trainee);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTrainee(null);
  };
  const handleTraineeChange = (event) => {
    const trainee = trainees.find(t => t.customer_id === event.target.value);
    setSelectedTrainee(trainee); 
  };

  return (
    <div
      className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="relative mx-4 px-7 py-6 w-[500px] overflow-y-auto bg-white rounded-lg overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between">
          <p className="font-bold text-lg">Add New Payment</p>
          <IoIosClose
            size={30}
            className="text-gray-500 cursor-pointer"
            onClick={() => onClose()}
          />
        </div>
        <div className="h-4"></div>
        <article>
          <label className="font-semibold mt-3">Trainee</label>
          <div className="h-2"></div>
          <Select
            fullWidth
            value={selectedTrainee?.customer_id || ''}  // Ensure value is never undefined
            onChange={handleTraineeChange}  // Update to handle the entire trainee object
          >
            {trainees?.map((trainee, index) => (
              <MenuItem key={index} value={trainee.customer_id}>
                {trainee.firstname} {trainee.lastname}
              </MenuItem>
            ))}
          </Select>
          { selectedTrainee != null && (
            <p className="mt-2 text-sm">
                Trainee Balance: {selectedTrainee?.balance}
            </p>
          )
          }
        </article>
        <article className="mt-3">
          <label className="font-semibold">Amount</label>
          <div className="h-2"></div>
          <TextField
            value={amount || ''}  // Ensure value is never undefined
            onChange={(e) => setAmount(e.target.value)}
            variant="outlined"
            type="number"
            inputProps={{ min: 1 }}
            fullWidth
          />
        </article>
        <article className="mt-3">
          <label htmlFor="" className="font-semibold">Note</label>
          <div className="h-2"></div>
          <TextField
            value={note || ''}  // Ensure value is never undefined
            onChange={(e) => setNote(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </article>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className='flex gap-x-3'>
                                    <article>
                    <label className='font-semibold'>Date</label>
                    <div className='h-2'></div>
                    <div>
                        <DatePicker
                            label=""
                            value={date}
                            onChange={(newValue) => setDate(newValue)}
                            slotProps={{ textField: { fullWidth: true } }}
                            maxDate={dayjs()} 
                        />
                    </div>
                </article>
                        
                    </div>
                </LocalizationProvider>
        <div className="flex justify-end mt-5">
        {trainees && trainees.length > 0 ? (
    <div className="flex items-center">
   <FormSubmitBtn
            text="Add Payment"
            isLoading={isLoading}
            onClick={() => handleAddPayment()}
          />
  </div>
) : (
  <div className="flex items-center">
   <span className='text-red-500'>Log at least one Trainee</span> 
    <div
      className="flex items-center justify-center bg-main text-white cursor-pointer hover:opacity-70 active:opacity-60 w-6 h-6 rounded-full ml-2"
      onClick={() => handleRowClick(null)}
    >
      <IoIosAdd size={25} />
    </div>
  </div>
)}
        </div>
        {errMsg && <p className="text-red-500 mt-3 text-center">{errMsg}</p>}
      </div>
      <Modal
              open={isModalOpen}
              onClose={handleCloseModal}
              trainee={selectedTraineeAccount}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <div>
                <AddTraineeModal 
                    trainee={selectedTraineeAccount}
                    onClose={handleCloseModal} 
                  />
              </div>
            </Modal>
    </div>
  );
};

export default AddPaymentModal;
