import React, { useRef } from 'react';
import { IoIosAdd } from 'react-icons/io';  // Import the default image

const ProfilePic = ({ imageSrc, setImageSrc, setImage }) => {
  const fileInputRef = useRef(null);

  // Trigger the file input when the image is clicked
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  // Handle the file change when a user uploads a new image
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);  // Store the file object in the parent state
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);  // Set the base64 image as imageSrc
      };
      reader.readAsDataURL(file);  // Read the file and convert to base64
    }
  };

  return (
    <div className='relative w-28 h-28 cursor-pointer border rounded-full' onClick={handleImageClick}>
      {/* Display current image or fallback to defaultPic */}
      <img
        src={imageSrc}  // Fallback to defaultPic if imageSrc is empty
        className='w-full h-full rounded-full object-cover'
      />

      {/* Add Icon */}
      <div className='w-7 h-7 shadow absolute bottom-0 flex items-center justify-center right-0 bg-white rounded-full'>
        <IoIosAdd size={30} />
      </div>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}  // Hide the file input element
      />
    </div>
  );
};

export default ProfilePic;
