import { Link } from 'react-router-dom';

export default function PageTitle({ button, isActive, onClick }) {
  return (
    <Link
      to={button.route}
      className={`relative linkk text-center lg:ml-0 ml-2 py-2 w-full inline-block z-10 text-gray-900 font-semibold leading-7 ${isActive ? 'active text-main' : ''}`}
      onClick={onClick}
    >
      <img src={button.image} alt={button.title} className='lg:w-12 lg:h-10 lg:ml-10 w-12 h-10 ml-3' />
      <span className='text-[11px] lg:text-xl'>{button.title}</span>
   
      {isActive && <div className="absolute bottom-0 left-0 lg:right-0 right-1 lg:border-b-1 border-b-2 border-blue-800 mb-2 lg:mb-0" />}
    </Link>
  );
}
