
import React from "react";
import { IoIosClose } from 'react-icons/io';
import {Link} from 'react-router-dom';

const Invoice = ({onClose}) => {
  return (
    <div 
    className='fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-50'
    onClick={onClose}
>
    <div
        className='relative px-7 py-2 w-[500px] h-[220px] overflow-y-auto bg-white rounded-lg shadow-lg'
        onClick={(e) => e.stopPropagation()}
    > 
        <div className='flex justify-between items-center mb-4'>
            <h1 className='text-xl font-semibold'>
                <u>How To?</u>
            </h1>
            <IoIosClose
                size={30}
                className="text-gray-500 cursor-pointer hover:text-gray-700 transition duration-200"
                onClick={onClose}
            />
        </div>
        <h2 className='text-lg font-medium mb-2'>
            <u>Send a Receipt?</u>
        </h2>
        <ol className='list-decimal list-inside'>
            <li>Go to <b><Link to='/trainees'>My Trainees</Link></b></li>
            <li>Select The Trainee's Account</li>
            <li>Press on <b>Send a Receipt</b></li>
            <li>Select the relevant sessions then <b>Share or Download</b></li>
        </ol>
    </div>
</div>
  )
}

export default Invoice