import React from 'react';
import { IoIosAdd } from 'react-icons/io';
import { MdDeleteOutline } from 'react-icons/md';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const Locations = ({ locations = [], setLocations }) => {
  
  const addInput = () => {
      setLocations([...locations, '']); 
  };

  const removeInput = (indexToRemove) => {
      setLocations(locations.filter((_, index) => index !== indexToRemove));
  };

  const updateSubtrainee = (indexToUpdate, value) => {
      const updatedSubtrainees = locations.map((location, index) =>
          index === indexToUpdate ? value : location
      );
      setLocations(updatedSubtrainees);
  };

  return (
    <div>
      <div className='flex'>
        <label className='font-semibold text-lg'>Locations</label>
        <div className='ml-4 bg-main h-6 w-6 hover:opacity-80 cursor-pointer text-white text-lg rounded-full'>
          <IoIosAdd size={25} onClick={addInput} />
        </div>
      </div>
      {
          Array.isArray(locations) && locations.map((subtrainee, index) => (
              <div key={index} className='flex items-center space-x-2 my-3'>
                  <TextField
                    value={subtrainee} 
                    placeholder="Enter location name"
                    onChange={(e) => updateSubtrainee(index, e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                  <MdDeleteOutline
                      size={25}
                      onClick={() => removeInput(index)}
                      style={{ cursor: "pointer" }}
                  />
              </div>
          ))
      }
    </div>
  );
}

Locations.propTypes = {
    locations: PropTypes.array,
    setLocations: PropTypes.func.isRequired,
};

export default Locations;
