import React from "react";
import { BarChart } from "@mui/x-charts";
import dayjs from "dayjs";

const WeeklySessionsChart = ({ data }) => {

  function getLast7Days() {
    const today = new Date(); 
    const dates = [];

    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i); 
      dates.push(date);
    }

    return dates;
  }

  return !data ? (
    <></>
  ) : (
    <BarChart
      xAxis={[
        {
          label: "Date",
          data: getLast7Days(),
          tickInterval: getLast7Days(),
          valueFormatter: (date) => dayjs(date).format("MMM D"),
          scaleType: 'band',
        },
      ]}
      yAxis={[
        {
          label: "No. of Sessions",
          valueFormatter: (value) => value.toFixed(0),
          min: 0,
          max: Math.max(...data) + 1, 
          tickNumber: Math.max(...data) + 1, 
        },
      ]}      
      
      series={[
        {
          label: "Sessions",
          data: data,
          color: "#069e20",
        },
      ]}
      height={400}
      width={400}
      slotProps={{ legend: { hidden: true } }}
    />
  );
};

export default WeeklySessionsChart;
