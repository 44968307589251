import React, { useEffect, useState } from 'react';
import { IoAdd } from "react-icons/io5";
import {  CircularProgress, Modal } from '@mui/material';
import AddTraineeModal from '../components/trainees/add_trainee';
import { useGetTraineesQuery } from '../redux/api/traineeApi';
import { Autocomplete,TextField, Button, Select, MenuItem } from '@mui/material';
import { FaPhone } from 'react-icons/fa6';
import LoadingPage from './loading_page';
import { PAGE_LIMIT } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import People from '../assets/people.png';
import Person from '../assets/person.png';
import Log from '../assets/log.png';

const TraineesPage = () => {
  const navigate = useNavigate();
  const [trainees, setTrainees] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [page, setPage] = useState(1);
  const { data: traineesResponse, error, isLoading } = useGetTraineesQuery({page, limit: PAGE_LIMIT,traineeName:searchValue});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [selectedTrainee, setSelectedTrainee] = useState(null);


  useEffect(() => {
    if (!traineesResponse) return;

    if (page == 1) {
      setTrainees(traineesResponse?.trainees)      
    } else {
      setTrainees((oldTrainees) => [...oldTrainees, ...traineesResponse?.trainees]);
    }
    setIsFetchingMore(false);
  }, [traineesResponse,searchValue]);  

  const handleRowClick = (trainee) => {
    setSelectedTrainee(trainee);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTrainee(null);
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (

    <div className='flex items-center justify-center px-5 md:px-10'>
      <div className='flex w-full px-3 max-w-5xl'>
        <div className='w-full'>
        <div className="w-full flex flex-col lg:flex-row space-x-2 lg:mr-0 mx-auto mt-4 lg:mt-0">
    <p className="flex items-center text-lg font-bold mb-4 mx-auto lg:mx-0">
      Trainees Log
      <img src={Log} className="w-5 h-6 ml-2" />
    </p>

    <div className="mt-1 mx-auto text-center lg:mx-0 lg:mb-5 flex justify-center items-center">
  <div
    className="w-6 h-6 bg-blue-900 flex items-center cursor-pointer hover:opacity-80 justify-center rounded-full"
    onClick={() => handleRowClick(null)}
  >
    <IoAdd color="white" size={20} />
  </div>
</div>

  </div>
  <div className="flex justify-center lg:justify-start mb-3 text-white lg:mt-0 mt-3">
  <div className="flex items-center bg-green-600 border rounded-lg px-3">
          <p className='my-2 '>
            Total: <span className='font-semibold'>{traineesResponse.total_trainees} trainees</span>
          </p>
          </div>
          </div>
          <p className='text-center mb-3 text-[14px] lg:text-lg'>Tap on the trainee to view all sessions and payments.</p>
          <div className="space-y-6">
          {trainees.length === 0 ? (
    <div className="text-sm text-center text-gray-500">
        You have not logged any trainees yet.
        <p
            className="text-logoMain underline font-semibold cursor-pointer mt-2"
            onClick={() => handleRowClick(null)}
        >
            Start Logging
        </p>
    </div>
) : (
    <>
        <Autocomplete
    value={searchValue}
    onInputChange={(e, newInputValue) => {
        setSearchValue(newInputValue);
        console.log(newInputValue);
    }}
    freeSolo={true}
    id="combo-box-demo"
    options={trainees
        ?.filter(trainee =>
            `${trainee.firstname} ${trainee.lastname}`
                .toLowerCase()
                .includes(searchValue?.toLowerCase() || '')
        )
        .map(trainee => `${trainee.firstname} ${trainee.lastname}`) ?? []}
    sx={{ width: "100%" }}
    renderInput={(params) => <TextField {...params} label="Search Trainee" />}
 />



        {trainees
            ?.filter(trainee => 
                `${trainee.firstname} ${trainee.lastname}`
                    .toLowerCase()
                    .includes(searchValue?.toLowerCase() || '')
            )
            .map((trainee, index) => (
                <div
                    key={index}
                    className="lg:p-4 py-2 px-4 text-sm lg:text-lg bg-white rounded-xl shadow-lg flex flex-row items-center space-y-1 transition transform hover:scale-105 lg:flex-row lg:space-y-0 lg:space-x-6 lg:justify-between"
                    style={{ backgroundColor: trainee.color }}
                    onClick={() => navigate(`/traineeaccount/${trainee.customer_id}`, { state: trainee })}
                >
                    <div className="flex flex-col lg:flex-row items-center lg:space-x-4 text-center lg:text-left">
                        <div className="flex-shrink-0 mb-2 lg:mb-0">
                            <img 
                                src={Person} 
                                alt="profile" 
                                className="w-12 h-12 rounded-full object-cover shadow-lg hidden md:block" 
                            />
                        </div>
                        <div className="flex flex-col">
                            <p className="text-sm lg:text-xl font-bold text-gray-800 mb-1">
                                {trainee.firstname} {trainee.lastname}
                            </p>
                            <p className="text-sm lg:text-sm text-gray-600 flex items-center justify-center lg:justify-start">
                                <FaPhone className="mr-2 text-gray-500" />
                                {trainee.telephone}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col items-center lg:items-start px-3">
                        <div className="flex items-center">
                            <img 
                                src={People} 
                                alt="people icon" 
                                className="w-12 h-10 text-gray-600 mr-2" 
                            />
                            <p className="text-sm lg:text-md font-medium text-gray-700">
                                {trainee.subtrainees.map((subtrainee, index) => (
                                    <span 
                                        key={index} 
                                        className="block text-xs lg:text-sm mb-1" 
                                    >
                                        {subtrainee.name}
                                    </span>
                                ))}
                            </p>
                        </div>
                    </div>
                    <div className="text-center lg:text-right ml-14 lg:ml-0">
                        <div className={`lg:py-2 px-1 lg:px-4 py-1 rounded-lg text-sm lg:text-md lg:text-lg font-semibold ${trainee.balance < 0 ? 'bg-red-100 text-red-500' : 'bg-green-100 text-green-500'}`}>
                            {trainee.balance < 0 ? '-' : '+'}${Math.abs(trainee.balance)}
                        </div>
                    </div>
                    <button
                        onClick={() => navigate(`/traineeaccount/${trainee.customer_id}`, { state: trainee })}
                        className="mt-3 hidden md:block lg:mt-0 bg-blue-600 text-white font-semibold py-2 px-5 rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all duration-200"
                    >
                        View Details
                    </button>
                </div>
            ))}
    </>
)}


</div>

{traineesResponse.current_page < traineesResponse.pages && searchValue === '' && (
  <div className='flex justify-center mt-6'>
    <button
      className='bg-main px-3 py-1 text-sm mt-3 rounded text-white'
      onClick={() => {
        setPage((page) => page + 1);
        setIsFetchingMore(true);
      }}
    >
      {isFetchingMore ? (
        <CircularProgress size={23} sx={{ color: 'white' }} />
      ) : (
        'Load More'
      )}
    </button>
  </div>
)}

          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            trainee={selectedTrainee}
            aria-labelledby='parent-modal-title'
            aria-describedby='parent-modal-description'
          >
            <div>
              <AddTraineeModal trainee={selectedTrainee} onClose={handleCloseModal} />
            </div>
          </Modal>
        </div>
      </div>
    </div>
    
   
  )
}

export default TraineesPage