import apiClient from "../api_client";

const getProfile = async () => {
  try {
    const response = await apiClient.get('/account');
    return response.data.data;
  } catch (error) {
    console.error('Error get profile: ' , error);
  }
}

const editProfile = async ({firstname, lastname, profname, gender, telephone,selectedSports, locations, yearsOfExperience, image, logo}) => {
  try {
    const formData = new FormData();

    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("profname", profname);
    formData.append("gender", gender);
    formData.append("telephone", telephone);
    if (Array.isArray(locations)) {
      locations.forEach((location) => {
        formData.append('locations[]', location);
      });
    } else {
      console.error('locations is not an array:', locations);
    }
    if (Array.isArray(selectedSports)) {
      selectedSports.forEach((sport) => {
        formData.append('sports[]', sport.sport_id); // Just append sport_id
        formData.append('sports_names[]', sport.name); // Optionally append the name
    });
    
  } else {
      console.error('selectedSports is not an array:', selectedSports);
  }
  
    
    formData.append("years_of_experience", yearsOfExperience);
    if (typeof(image) !== 'string') {
        formData.append("image", image);
    }
    if (typeof(logo) !== 'string') {
        formData.append("logo", logo);
    }

    const response = await apiClient.post('/account', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return {
      "success": true,
      "data": response.data.data,
    }
  } catch (error) {
    console.log(error);
    return {
      "success": false,
      "error": Array.isArray(error.response?.data?.error[0]) ?
          error.response?.data?.error[0][0] :
          error.response?.data?.error[0],
    }
  }
}

export const profileService = {
    getProfile,
    editProfile,
}